import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { States } from '../../_redux/root-reducers'
import ToastComponent from './component'
import { toastHide } from '../../actions/toast-action'

function mapStateToProps(state: States) {
  return {
    toast: state.toast,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    handler: {
      hide() {
        dispatch(toastHide())
      },
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastComponent)
