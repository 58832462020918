import { ErrorInterface } from '../interfaces/commons-interface'
import { RegistrationInterface } from '../interfaces/registration-interface'
import {
  REGISTRATION_INPUT,
  REGISTRATION_SUBMIT,
  REGISTRATION_SUBMIT_SUCCESS,
  REGISTRATION_SUBMIT_FAILURE,
} from '../states/registration-state'

export function registrationInput(name: string, value: string) {
  return {
    type: REGISTRATION_INPUT,
    payload: { name, value },
  }
}

export function registrationSubmit() {
  return {
    type: REGISTRATION_SUBMIT,
  }
}

export function registrationSuccess(data: RegistrationInterface) {
  return {
    type: REGISTRATION_SUBMIT_SUCCESS,
    payload: { data },
  }
}

export function registrationSubmitFailure(error: ErrorInterface) {
  return {
    type: REGISTRATION_SUBMIT_FAILURE,
    payload: error,
  }
}
