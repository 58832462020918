import { ErrorInterface } from '../interfaces/commons-interface'
import {
  FINISH_PAYMENT_CASH,
  FINISH_PAYMENT_CASH_SUCCESS,
  FINISH_PAYMENT_CASH_FAILURE
} from '../states/finish-payment-cash-state'

export function finishPaymentCash() {
  return {
    type: FINISH_PAYMENT_CASH
  }
}

export function finishPaymentCashSuccess() {
  return {
    type: FINISH_PAYMENT_CASH_SUCCESS
  }
}

export function finishPaymentCashFailure(error: ErrorInterface) {
  return {
    type: FINISH_PAYMENT_CASH_FAILURE,
    payload: { error }
  }
}
