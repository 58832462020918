import { ErrorInterface } from '../interfaces/commons-interface'
import { InvitationInterface } from '../interfaces/invitation-interface'
import {
  INVITATION_SET_CODE,
  INVITATION_INPUT,

  INVITATION_VERIFY,
  INVITATION_VERIFY_SUCCESS,
  INVITATION_VERIFY_FAILURE,
} from '../states/invitation-state'

export function invitationInput(name: string, value: string) {
  return {
    type: INVITATION_INPUT,
    payload: { name, value },
  }
}

export function invitationSetCode(invitationCode: string, tableCode: string) {
  return {
    type: INVITATION_SET_CODE,
    payload: { invitationCode, tableCode },
  }
}

export function invitationVerify() {
  return {
    type: INVITATION_VERIFY,
  }
}

export function invitationVerifySuccess(data: InvitationInterface) {
  return {
    type: INVITATION_VERIFY_SUCCESS,
    payload: { data },
  }
}

export function invitationVerifyFailure(error: ErrorInterface) {
  return {
    type: INVITATION_VERIFY_FAILURE,
    payload: error,
  }
}
