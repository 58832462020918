import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { CartOrderFormInterface, CartInterface, CartOrderDetailInterface } from '../interfaces/cart-interface'

export const CART_FETCH_API = 'CART_FETCH_API'
export const CART_FETCH_API_SUCCESS = 'CART_FETCH_API_SUCCESS'
export const CART_FETCH_API_FAILURE = 'CART_FETCH_API_FAILURE'

export const CART_POST_API = 'CART_POST_API'

export const CART_PATCH_API = 'CART_PATCH_API'

export const CART_SHOW_NOTES = 'CART_SHOW_NOTES'
export const CART_HIDE_NOTES = 'CART_HIDE_NOTES'
export const CART_NOTES_INPUT = 'CART_NOTES_INPUT'

export const CART_DELETE_API = 'CART_DELETE_API'

export const CART_TOTAL_ITEMS = 'CART_TOTAL_ITEMS'
export const CART_TOTAL_ITEMS_SUCCESS = 'CART_TOTAL_ITEMS_SUCCESS'

export const CART_SUBMIT_ORDER = 'CART_SUBMIT_ORDER'
export const CART_SUBMIT_ORDER_SUCCESS = 'CART_SUBMIT_ORDER_SUCCESS'
export const CART_SUBMIT_ORDER_FAILURE = 'CART_SUBMIT_ORDER_FAILURE'

export interface CartState {
  data?: CartInterface
  orders?: CartOrderDetailInterface[]
  forms?: CartOrderFormInterface
  error: ErrorInterface
  items: number
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: CartState = {
  error: {
    code: '',
    message: '',
  },
  items: 0,
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [CART_FETCH_API]: (state: CartState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [CART_FETCH_API_SUCCESS]: (state: CartState, { payload }: { payload: CartState }) => ({
    ...state,
    data: payload.data,
    forms: {
      ...state.forms,
      xid: '',
      variants: [],
      additionals: [],
      quantityOrdered: 0,
      notes: '',
      success: false,
    },
    isLoading: false,
    isSuccess: true,
  }),
  [CART_FETCH_API_FAILURE]: (state: CartState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),

  [CART_POST_API]: (state: CartState, { payload }: { payload: CartOrderFormInterface }) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    forms: {
      ...state.forms,
      xid: payload.xid,
      variants: payload.variants,
      additionals: payload.additionals,
      quantityOrdered: payload.quantityOrdered,
      notes: payload.notes,
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),

  [CART_SHOW_NOTES]: (state: CartState, { payload }: { payload: CartOrderFormInterface }) => ({
    ...state,
    forms: {
      ...state.forms,
      xid: payload.xid,
      notes: payload.notes,
      quantityOrdered: payload.quantityOrdered,
      isNotesOpen: true,
    },
  }),
  [CART_NOTES_INPUT]: (state: CartState, { payload }: { payload: CartOrderFormInterface }) => ({
    ...state,
    forms: {
      ...state.forms,
      notes: payload.notes,
    },
  }),
  [CART_HIDE_NOTES]: (state: CartState) => ({
    ...state,
    forms: {
      ...state.forms,
      xid: '',
      notes: '',
      isNotesOpen: false,
    },
  }),

  [CART_PATCH_API]: (state: CartState, { payload }: { payload: CartOrderFormInterface }) => ({
    ...state,
    forms: {
      ...state.forms,
      xid: payload.xid,
      quantityOrdered: payload.quantityOrdered,
      notes: payload.notes,
    },
  }),

  [CART_DELETE_API]: (state: CartState, { payload }: { payload: CartOrderFormInterface }) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    forms: {
      ...state.forms,
      xid: payload.xid,
      variants: [],
      additionals: [],
      quantityOrdered: 0,
      notes: '',
      success: false,
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),

  [CART_TOTAL_ITEMS]: (state: CartState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [CART_TOTAL_ITEMS_SUCCESS]: (state: CartState, { payload }: { payload: { items: number } }) => ({
    ...state,
    items: payload.items,
    isLoading: false,
    isSuccess: true,
    isFailure: false,
  }),

  [CART_SUBMIT_ORDER]: (state: CartState, { payload }: { payload: CartOrderFormInterface }) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    forms: {
      ...state.forms,
      signature: payload.signature,
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [CART_SUBMIT_ORDER_SUCCESS]: (state: CartState, { payload }: { payload: { success: boolean } }) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    forms: {
      ...state.forms,
      xid: '',
      variants: [],
      additionals: [],
      quantityOrdered: 0,
      notes: '',
      success: false,
    },
    isLoading: true,
    isSuccess: payload.success,
    isFailure: false,
  }),
}

export default reducer(Object.assign(reducers), initialState)
