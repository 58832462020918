import reducer from '../_utils/reducer'
import { States } from '../_redux/root-reducers'
import { InvitationCodeInterface, InvitationCodeForms } from '../interfaces/invitation-code-interface'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'

export const INVITATION_CODE_VERIFY = 'INVITATION_CODE_VERIFY'
export const INVITATION_CODE_VERIFY_SUCCESS = 'INVITATION_CODE_VERIFY_SUCCESS'
export const INVITATION_CODE_VERIFY_FAILURE = 'INVITATION_CODE_VERIFY_FAILURE'

export const INVITATION_CODE_INPUT_CODE = 'INVITATION_CODE_INPUT_CODE'

export interface InvitationCodeState {
  data?: InvitationCodeInterface
  error: ErrorInterface
  forms: InvitationCodeForms
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: InvitationCodeState = {
  error: {
    code: '',
    message: '',
  },
  forms: {
    tableCode: '',
    tableXid: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [INVITATION_CODE_VERIFY]: (state: InvitationCodeState, { payload }: { payload: { tableXid: string } }) => ({
    ...state,
    forms: {
      ...state.forms,
      tableXid: payload.tableXid
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [INVITATION_CODE_VERIFY_SUCCESS]: (state: InvitationCodeState, { payload }: { payload: InvitationCodeState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [INVITATION_CODE_VERIFY_FAILURE]: (state: InvitationCodeState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),

  [INVITATION_CODE_INPUT_CODE]: (state: InvitationCodeState, { payload }: { payload: { tableCode: string } }) => ({
    ...state,
    forms: {
      ...state.forms,
      tableCode: payload.tableCode
    },
  }),
}

export const getInvitationCodeSelector = (states: States) => states.invitationCode
export const getInvitationCodeIsLoadingSelector = (states: States) => states.invitationCode.isLoading
export const getInvitationCodeIsSuccessSelector = (states: States) => states.invitationCode.isSuccess
export const getInvitationCodeIsFailureSelector = (states: States) => states.invitationCode.isFailure
export const getInvitationCodeErrorSelector = (states: States) => states.invitationCode.error
export const getInvitationCodeDataSelector = (states: States) => states.invitationCode.data

export default reducer(Object.assign(reducers), initialState)
