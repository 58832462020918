import { combineReducers } from 'redux'
import { StateObservable } from 'redux-observable'
import { connectRouter } from 'connected-react-router'

import { history } from '../history'

// App
import app, { AppState } from '../states/app-state'

// Components
import toast, { ToastState } from '../states/toast-state'
import alertConfirmation, { AlertConfirmationState } from '../states/alert-confirmation-state'

// Modules
import qrcode, { QrcodeState } from '../states/qrcode-state'
import invitation, { InvitationState } from '../states/invitation-state'
import registration, { RegistrationState } from '../states/registration-state'
import join, { JoinState } from '../states/join-state'
import help, { HelpState } from '../states/help-state'
import reservation, { ReservationState } from '../states/reservation-state'
import menu, { MenuState } from '../states/menu-state'
import menuCategory, { MenuCategoryState } from '../states/menu-category-state'
import menuDetail, { MenuDetailState } from '../states/menu-detail-state'
import cart, { CartState } from '../states/cart-state'
import order, { OrderState } from '../states/order-state'
import summary, { SummaryState } from '../states/summary-state'
import review, { ReviewState } from '../states/review-state'
import invoice, { InvoiceState } from '../states/invoice-state'
import paymentCash, { PaymentCashState } from '../states/payment-cash-state'
import invitationCode, { InvitationCodeState } from '../states/invitation-code-state'
import finishPaymentCash, { FinishPaymentCashState } from '../states/finish-payment-cash-state'

const appReducer = combineReducers({
  router: connectRouter(history),

  // App
  app,
  toast,
  alertConfirmation,

  // QR Code
  qrcode,

  // Invitation
  invitation,
  invitationCode,

  // Registration
  registration,

  // Join
  join,

  // Help
  help,

  // Reservation
  reservation,

  // Menu
  menu,
  menuCategory,
  menuDetail,

  // Cart
  cart,

  // Order
  order,

  // Summary
  summary,

  // Review / Survey
  review,

  // Invoice
  invoice,

  // Payment
  paymentCash,
  finishPaymentCash,
})

export interface States {
  app: AppState
  toast: ToastState
  alertConfirmation: AlertConfirmationState
  qrcode: QrcodeState
  registration: RegistrationState
  join: JoinState
  help: HelpState
  invitation: InvitationState
  invitationCode: InvitationCodeState
  reservation: ReservationState
  menu: MenuState
  menuCategory: MenuCategoryState
  menuDetail: MenuDetailState
  cart: CartState
  order: OrderState
  summary: SummaryState
  review: ReviewState
  invoice: InvoiceState
  paymentCash: PaymentCashState
  finishPaymentCash: FinishPaymentCashState
}

export type ObservableStates = StateObservable<States>

const rootReducers = (state: any, action: any) => {
  switch (action.type) {
    case 'CLEAR_DATA':
    case 'LOGOUT_API':
      return appReducer(undefined, action)
    case '@@router/LOCATION_CHANGE':
      switch (action.payload.action) {
        case 'REPLACE':
          return appReducer(state, action)
        default:
          return appReducer(undefined, action)
      }
    default:
      return appReducer(state, action)
  }
}

export default rootReducers
