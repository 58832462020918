import { ErrorInterface } from '../interfaces/commons-interface'
import { MenuCategoryInterface } from '../interfaces/menu-category-interface'
import {
  MENU_CATEGORY_FETCH_API,
  MENU_CATEGORY_FETCH_API_SUCCESS,
  MENU_CATEGORY_FETCH_API_FAILURE,
} from '../states/menu-category-state'

export function menuCategoryFetchApi() {
  return {
    type: MENU_CATEGORY_FETCH_API,
  }
}

export function menuCategoryFetchApiSuccess(data: MenuCategoryInterface) {
  return {
    type: MENU_CATEGORY_FETCH_API_SUCCESS,
    payload: { data },
  }
}

export function menuCategoryFetchApiFailure(error: ErrorInterface) {
  return {
    type: MENU_CATEGORY_FETCH_API_FAILURE,
    payload: error,
  }
}
