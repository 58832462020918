import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { States } from '../_redux/root-reducers'

export const HELP = 'HELP'
export const HELP_SUCCESS = 'HELP_SUCCESS'
export const HELP_FAILURE = 'HELP_FAILURE'

export interface HelpState {
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: HelpState = {
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [HELP]: (state: HelpState) => ({
    ...state,
    error: {
      ...state.error,
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [HELP_SUCCESS]: (state: HelpState) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    isFailure: false,
  }),
  [HELP_FAILURE]: (state: HelpState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isSuccess: false,
    isFailure: true,
  }),
}

export const getHelpSelector = (state: States) => state.help
export const getHelpIsLoadingSelector = (state: States) => state.help.isLoading
export const getHelpIsSuccessSelector = (state: States) => state.help.isSuccess
export const getHelpIsFailureSelector = (state: States) => state.help.isFailure

export default reducer(Object.assign(reducers), initialState)
