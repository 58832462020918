import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { MenuDetailResponseInterface } from '../interfaces/menu-detail-interface'
import { MENU_DETAIL_FETCH_API } from '../states/menu-detail-state'
import { menuDetailFetchApiSuccess, menuDetailFetchApiFailure } from '../actions/menu-detail-action'

// noinspection JSUnusedGlobalSymbols
export function menuDetailFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(MENU_DETAIL_FETCH_API),
    map(() => ({
      xid: state$.value.menuDetail.data?.xid
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          method: 'GET',
          url: `/customers/menus/${data.xid}`,
        })
        .pipe(
          map(({ data: { data } }: MenuDetailResponseInterface) => menuDetailFetchApiSuccess(data)),
          catchError((error) => of(menuDetailFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(menuDetailFetchApiFailure(error))),
  )
}
