import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import Cookies from 'js-cookie'

import env from '../_configs/env'
import { ObservableStates } from '../_redux/root-reducers'
import { APP_ACCESS_TOKEN_KEY_NAME } from '../_configs/token'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { InvoiceResponseInterface } from '../interfaces/invoice-interface'
import { INVOICE_FETCH_API } from '../states/invoice-state'
import { invoiceFetchApiSuccess, invoiceFetchApiFailure } from '../actions/invoice-actions'
import { SessionResponseInterface } from '../interfaces/session-interface'

// noinspection JSUnusedGlobalSymbols
export function invoiceFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(INVOICE_FETCH_API),
    map(() => ({
      xid: state$.value.invoice.xid,
    })),
    mergeMap((params) =>
      dependencies
        .baseClient({
          method: 'POST',
          url: '/customers/anon/sessions',
          auth: {
            username: env.apiClientId,
            password: env.apiClientSecret,
          },
        })
        .pipe(
          mergeMap(({ data: { data } }: SessionResponseInterface) => {
            const { token, expiredAt } = data.session
            const expires = new Date(0)

            expires.setUTCSeconds(expiredAt)

            Cookies.set(APP_ACCESS_TOKEN_KEY_NAME, token, { expires })

            return dependencies
              .baseClient({
                method: 'GET',
                url: `/invoices/${params.xid}`,
              })
              .pipe(
                map(({ data: { data } }: InvoiceResponseInterface) => invoiceFetchApiSuccess(data)),
                catchError((error) => of(invoiceFetchApiFailure(error))),
              )
          }),
          catchError((error) => of(invoiceFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(invoiceFetchApiFailure(error))),
  )
}
