import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { OrderInterface } from '../interfaces/order-interface'

export const ORDER_FETCH_API = "ORDER_FETCH_API"
export const ORDER_FETCH_API_SUCCESS = "ORDER_FETCH_API_SUCCESS"
export const ORDER_FETCH_API_FAILURE = "ORDER_FETCH_API_FAILURE"

export interface OrderState {
  data?: OrderInterface
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: OrderState = {
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false
}

export const reducers = {
  [ORDER_FETCH_API]: (state: OrderState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false
  }),
  [ORDER_FETCH_API_SUCCESS]: (state: OrderState, { payload }: { payload: OrderState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [ORDER_FETCH_API_FAILURE]: (state: OrderState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export default reducer(Object.assign(reducers), initialState)
