import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import Cookies from 'js-cookie'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { RegistrationResponseInterface } from '../interfaces/registration-interface'
import { appLoaded } from '../actions/app-action'
import {
  REGISTRATION_SUBMIT,
  REGISTRATION_SUBMIT_SUCCESS,
  REGISTRATION_SUBMIT_FAILURE,
} from '../states/registration-state'
import { registrationSuccess, registrationSubmitFailure } from '../actions/registration-action'
import { USER_ACCESS_TOKEN_KEY_NAME } from '../_configs/token'

// noinspection JSUnusedGlobalSymbols
export function registrationSubmitService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface
) {
  return action$.pipe(
    ofType(REGISTRATION_SUBMIT),
    map(() => ({
      tableXid: state$.value.qrcode.data?.table.xid,
      name: state$.value.registration.forms.name,
      phone: state$.value.registration.forms.phone,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/tables/reserve',
        })
        .pipe(
          map(({ data: { data } }: RegistrationResponseInterface) => {
            const { session } = data
            const expires = new Date(0)

            expires.setUTCSeconds(session.expiredAt)

            Cookies.set(USER_ACCESS_TOKEN_KEY_NAME, session.token, { expires })

            return registrationSuccess(data)
          }),
          catchError((error) => of(registrationSubmitFailure(error)))
        )
    ),
    catchError((error) => of(registrationSubmitFailure(error)))
  )
}

// noinspection JSUnusedGlobalSymbols
export function registrationSubmitSuccessService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(REGISTRATION_SUBMIT_SUCCESS),
    map(() => appLoaded())
  )
}

// noinspection JSUnusedGlobalSymbols
export function registrationSubmitFailureService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(REGISTRATION_SUBMIT_FAILURE),
    map(() => appLoaded())
  )
}
