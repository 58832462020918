import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { OrderResponseInterface } from '../interfaces/order-interface'
import { ORDER_FETCH_API } from '../states/order-state'
import { orderFetchApiSuccess, orderFetchApiFailure } from '../actions/order-action'

// noinspection JSUnusedGlobalSymbols
export function menuFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(ORDER_FETCH_API),
    debounceTime(500),
    mergeMap((params) =>
      dependencies
        .baseClient({
          method: 'GET',
          url: '/customers/tables/orders/ongoing',
        })
        .pipe(
          map(({ data: { data } }: OrderResponseInterface) => orderFetchApiSuccess(data)),
          catchError((error) => of(orderFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(orderFetchApiFailure(error))),
  )
}
