import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, debounceTime } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { SummaryResponseInterface } from '../interfaces/summary-interface'
import { SUMMARY_FETCH_API } from '../states/summary-state'
import { summaryFetchApiSuccess, summaryFetchApiFailure } from '../actions/summary-action'

// noinspection JSUnusedGlobalSymbols
export function menuDetailFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(SUMMARY_FETCH_API),
    debounceTime(500),
    mergeMap(() =>
      dependencies
        .baseClient({
          method: 'GET',
          url: '/customers/tables/orders/summary',
        })
        .pipe(
          map(({ data: { data } }: SummaryResponseInterface) => summaryFetchApiSuccess(data)),
          catchError((error) => of(summaryFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(summaryFetchApiFailure(error))),
  )
}
