import reducer from '../_utils/reducer'
import { ErrorInterface, InputInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { RegistrationInterface } from '../interfaces/registration-interface'
import { States } from '../_redux/root-reducers'

export const REGISTRATION_INPUT = 'REGISTRATION_INPUT'

export const REGISTRATION_SUBMIT = 'REGISTRATION_SUBMIT'
export const REGISTRATION_SUBMIT_SUCCESS = 'REGISTRATION_SUBMIT_SUCCESS'
export const REGISTRATION_SUBMIT_FAILURE = 'REGISTRATION_SUBMIT_FAILURE'

export interface RegistrationState {
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
  forms: {
    name: string
    phone: string
  }
  data?: RegistrationInterface
  error: ErrorInterface
}

export const initialState: RegistrationState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  forms: {
    name: '',
    phone: '',
  },
  error: {
    code: '',
    message: '',
  },
}

export const reducers = {
  [REGISTRATION_INPUT]: (state: RegistrationState, { payload }: { payload: InputInterface }) => ({
    ...state,
    forms: {
      ...state.forms,
      [payload.name]: payload.value,
    },
  }),

  [REGISTRATION_SUBMIT]: (state: RegistrationState) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    isFailure: false,
    error: {
      code: '',
      message: '',
    },
  }),
  [REGISTRATION_SUBMIT_SUCCESS]: (state: RegistrationState, { payload }: { payload: RegistrationState }) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    data: payload.data,
  }),
  [REGISTRATION_SUBMIT_FAILURE]: (state: RegistrationState, { payload }: PayloadErrorInterface) => ({
    ...state,
    isLoading: false,
    isFailure: true,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
  }),
}

export const getRegistrationSelector = (states: States) => states.registration
export const getRegistrationIsLoadingSelector = (states: States) => states.registration.isLoading
export const getRegistrationIsSuccessSelector = (states: States) => states.registration.isSuccess
export const getRegistrationIsFailureSelector = (states: States) => states.registration.isFailure
export const getRegistrationFormsSelector = (states: States) => states.registration.forms
export const getRegistrationErrorSelector = (states: States) => states.registration.error
export const getRegistrationDataSelector = (states: States) => states.registration.data

export default reducer(Object.assign(reducers), initialState)
