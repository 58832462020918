import { FC, lazy, LazyExoticComponent, VFC } from 'react'
import { RouteProps } from 'react-router'

export enum Guards {
  QRCODE,
  REGISTRATION,
  JOIN,
  INVITATION,
  RESERVATION,
  SUMMARY,
  REVIEW,
  DONE,
}

export interface Routes extends RouteProps {
  view: LazyExoticComponent<() => JSX.Element> | LazyExoticComponent<FC> | LazyExoticComponent<VFC>
  guard?: Guards
}

const routes: Routes[] = [
  {
    path: '/',
    view: lazy(() => import('../views/home')),
    guard: Guards.RESERVATION,
  },
  {
    path: '/home',
    view: lazy(() => import('../views/home')),
    guard: Guards.RESERVATION,
  },
  {
    path: '/qrcode/:code',
    view: lazy(() => import('./../views/qrcode')),
    guard: Guards.QRCODE,
  },
  {
    path: '/registration',
    view: lazy(() => import('../views/registration')),
    guard: Guards.REGISTRATION,
  },
  {
    path: '/registered',
    view: lazy(() => import('./../views/registered')),
    guard: Guards.RESERVATION,
  },
  {
    path: '/invitation/:invitationCode/:tableCode',
    view: lazy(() => import('./../views/invitation')),
    guard: Guards.INVITATION,
  },
  {
    path: '/invitation-code/:tableXid',
    view: lazy(() => import('./../views/invitation-code')),
    guard: Guards.INVITATION,
  },
  {
    path: '/join',
    view: lazy(() => import('./../views/join')),
    guard: Guards.JOIN,
  },
  {
    path: '/categories/:categoryXid',
    view: lazy(() => import('./../views/menu/category')),
    guard: Guards.RESERVATION,
  },
  {
    path: '/menus/:menuId',
    view: lazy(() => import('./../views/menu/detail/MenuDetails')),
    guard: Guards.RESERVATION,
  },
  {
    path: '/carts',
    view: lazy(() => import('./../views/order/cart/Cart')),
    guard: Guards.RESERVATION,
  },
  {
    path: '/orders',
    view: lazy(() => import('./../views/order/close-order/CloseOrder')),
    guard: Guards.RESERVATION,
  },
  {
    path: '/summary',
    view: lazy(() => import('../views/summary/PaymentSummary')),
    guard: Guards.SUMMARY,
  },
  {
    path: '/payment-cash',
    view: lazy(() => import('../views/payment/cash/PaymentCash')),
    guard: Guards.SUMMARY,
  },
  {
    path: '/payment-ewallet',
    view: lazy(() => import('../views/payment/ewallet/PaymentEwallet')),
    guard: Guards.SUMMARY,
  },
  {
    path: '/invoice/:orderXid',
    view: lazy(() => import('../views/invoice')),
  },
  {
    path: '/closed',
    view: lazy(() => import('../views/payment-order-close/PaymentOrderClose')),
    guard: Guards.REVIEW,
  },
  {
    path: '/review',
    view: lazy(() => import('../views/payment/success/PaymentSuccess')),
    guard: Guards.REVIEW,
  },
  {
    path: '/done',
    view: lazy(() => import('../views/done')),
    guard: Guards.DONE,
  },
  {
    path: '*',
    // TODO: refactor to ConnectedComponent
    view: lazy(() => import('./../pages/error/NotFoundPage')),
  },
]

export default routes
