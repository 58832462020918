import reducer from '../_utils/reducer'
import { States } from '../_redux/root-reducers'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'

export const FINISH_PAYMENT_CASH = 'FINISH_PAYMENT_CASH'
export const FINISH_PAYMENT_CASH_SUCCESS = 'FINISH_PAYMENT_CASH_SUCCESS'
export const FINISH_PAYMENT_CASH_FAILURE = 'FINISH_PAYMENT_CASH_FAILURE'

export interface FinishPaymentCashState {
  data?: null
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: FinishPaymentCashState = {
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [FINISH_PAYMENT_CASH]: (state: FinishPaymentCashState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [FINISH_PAYMENT_CASH_SUCCESS]: (state: FinishPaymentCashState) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
  }),
  [FINISH_PAYMENT_CASH_FAILURE]: (state: FinishPaymentCashState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  })
}

export const getFinishPaymentCashSelector = (state: States) => state.finishPaymentCash
export const getFinishPaymentCashErrorSelector = (state: States) => state.finishPaymentCash.error
export const getFinishPaymentCashIsLoadingSelector = (state: States) => state.finishPaymentCash.isLoading
export const getFinishPaymentCashIsSuccessSelector = (state: States) => state.finishPaymentCash.isSuccess
export const getFinishPaymentCashIsFailureSelector = (state: States) => state.finishPaymentCash.isFailure

export default reducer(Object.assign(reducers), initialState)
