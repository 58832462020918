import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import Cookies from 'js-cookie'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { JoinResponseInterface } from '../interfaces/join-interface'
import { appLoaded } from '../actions/app-action'
import { JOIN_SUBMIT, JOIN_SUBMIT_FAILURE, JOIN_SUBMIT_SUCCESS } from '../states/join-state'
import { joinSubmitFailure, joinSuccess } from '../actions/join-action'
import { USER_ACCESS_TOKEN_KEY_NAME } from '../_configs/token'
import { toastError } from '../actions/toast-action'

// noinspection JSUnusedGlobalSymbols
export function joinSubmitService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(JOIN_SUBMIT),
    map(() => ({
      invitationCode: state$.value.invitation.invitationCode || state$.value.invitationCode.data?.table.invitationCode,
      name: state$.value.join.forms.name,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/tables/invitations/join',
        })
        .pipe(
          map(({ data: { data } }: JoinResponseInterface) => {
            const { session } = data
            const expires = new Date(0)

            expires.setUTCSeconds(session.expiredAt)

            Cookies.set(USER_ACCESS_TOKEN_KEY_NAME, session.token, { expires })

            return joinSuccess(data)
          }),
          catchError((error) => of(joinSubmitFailure(error))),
        ),
    ),
    catchError((error) => of(joinSubmitFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function joinSubmitSuccessService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(JOIN_SUBMIT_SUCCESS),
    map(() => appLoaded()),
  )
}

// noinspection JSUnusedGlobalSymbols
export function joinSubmitFailureService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(JOIN_SUBMIT_FAILURE),
    mergeMap(() => of(
      appLoaded(),
      toastError('Nama sudah digunakan'),
    )),
  )
}
