import reducer from '../_utils/reducer'
import { ErrorInterface, InputInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { JoinInterface } from '../interfaces/join-interface'
import { States } from '../_redux/root-reducers'

export const JOIN_INPUT = 'JOIN_INPUT'

export const JOIN_SUBMIT = 'JOIN_SUBMIT'
export const JOIN_SUBMIT_SUCCESS = 'JOIN_SUBMIT_SUCCESS'
export const JOIN_SUBMIT_FAILURE = 'JOIN_SUBMIT_FAILURE'

export interface JoinState {
  forms: {
    name: string
  }
  data?: JoinInterface
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: JoinState = {
  forms: {
    name: '',
  },
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [JOIN_INPUT]: (state: JoinState, { payload }: { payload: InputInterface }) => ({
    ...state,
    forms: {
      ...state.forms,
      [payload.name]: payload.value,
    },
  }),

  [JOIN_SUBMIT]: (state: JoinState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [JOIN_SUBMIT_SUCCESS]: (state: JoinState, { payload }: { payload: JoinState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [JOIN_SUBMIT_FAILURE]: (state: JoinState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export const getJoinSelector = (states: States) => states.join
export const getJoinIsLoadingSelector = (states: States) => states.join.isLoading
export const getJoinIsSuccessSelector = (states: States) => states.join.isSuccess
export const getJoinIsFailureSelector = (states: States) => states.join.isFailure
export const getJoinErrorSelector = (states: States) => states.join.error
export const getJoinDataSelector = (states: States) => states.join.data

export default reducer(Object.assign(reducers), initialState)
