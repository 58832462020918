import { ErrorInterface } from '../interfaces/commons-interface'
import { SummaryInterface } from '../interfaces/summary-interface'
import {
  SUMMARY_FETCH_API,
  SUMMARY_FETCH_API_SUCCESS,
  SUMMARY_FETCH_API_FAILURE,
  SUMMARY_INPUT,
} from '../states/summary-state'

export function summaryFetchApi() {
  return {
    type: SUMMARY_FETCH_API,
  }
}

export function summaryFetchApiSuccess(data: SummaryInterface) {
  return {
    type: SUMMARY_FETCH_API_SUCCESS,
    payload: { data },
  }
}

export function summaryFetchApiFailure(error: ErrorInterface) {
  return {
    type: SUMMARY_FETCH_API_FAILURE,
    payload: error,
  }
}

export function summaryInput(name: string, value: string) {
  return {
    type: SUMMARY_INPUT,
    payload: { name, value },
  }
}
