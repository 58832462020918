import reducer from '../_utils/reducer'
import { ReservationInterface } from '../interfaces/reservation-interface'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { States } from '../_redux/root-reducers'

export const RESERVATION_ME_API = 'RESERVATION_ME_API'
export const RESERVATION_ME_API_SUCCESS = 'RESERVATION_ME_API_SUCCESS'
export const RESERVATION_ME_API_FAILURE = 'RESERVATION_ME_API_FAILURE'

export interface ReservationState {
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
  data?: ReservationInterface
  error: ErrorInterface
}

export const initialState: ReservationState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  error: {
    code: '',
    message: '',
  },
}

export const reducers = {
  [RESERVATION_ME_API]: (state: ReservationState) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [RESERVATION_ME_API_SUCCESS]: (state: ReservationState, { payload }: { payload: ReservationState }) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    data: payload.data,
  }),
  [RESERVATION_ME_API_FAILURE]: (state: ReservationState, { payload }: PayloadErrorInterface) => ({
    ...state,
    isLoading: false,
    isFailure: true,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
  }),
}

export const getReservationMeIsLoadingSelector = (state: States) => state.reservation.isLoading
export const getReservationMeIsSuccessSelector = (state: States) => state.reservation.isSuccess
export const getReservationMeIsFailureSelector = (state: States) => state.reservation.isFailure
export const getReservationMeDataSelector = (state: States) => state.reservation.data
export const getReservationMeErrorSelector = (state: States) => state.reservation.error

export default reducer(Object.assign(reducers), initialState)
