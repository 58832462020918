import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { RESERVATION_ME_API, RESERVATION_ME_API_FAILURE } from '../states/reservation-state'
import { ReservationResponseInterface } from '../interfaces/reservation-interface'
import { reservationMeApiFailure, reservationMeApiSuccess } from '../actions/reservation-action'
import { REGISTRATION_SUBMIT_SUCCESS } from '../states/registration-state'
import { JOIN_SUBMIT_SUCCESS } from '../states/join-state'
import { clearCookies } from '../_utils/user'

// noinspection JSUnusedGlobalSymbols
export function reservationMeApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(RESERVATION_ME_API, REGISTRATION_SUBMIT_SUCCESS, JOIN_SUBMIT_SUCCESS),
    mergeMap(() =>
      dependencies
        .baseClient({
          method: 'GET',
          url: '/customers/tables/reservations/me',
        })
        .pipe(
          map(({ data: { data } }: ReservationResponseInterface) => reservationMeApiSuccess(data)),
          catchError((error) => of(reservationMeApiFailure(error))),
        ),
    ),
    catchError((error) => of(reservationMeApiFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function reservationMeApiFailureService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(RESERVATION_ME_API_FAILURE),
    map(() => {
      clearCookies()

      return { type: 'CLEAR_DATA' }
    }),
  )
}
