import Cookies from 'js-cookie'

import { persistConfig } from '../_redux'
import { APP_ACCESS_TOKEN_KEY_NAME, USER_ACCESS_TOKEN_KEY_NAME } from '../_configs/token'

export const hasLogged = () => {
  return Cookies.get(APP_ACCESS_TOKEN_KEY_NAME) !== undefined && Cookies.get(USER_ACCESS_TOKEN_KEY_NAME) !== null
}

export const getData = () => {
  const persist = localStorage.getItem(persistConfig.keyPrefix + persistConfig.key)

  const data = {
    qrcode: false,
    registration: false,
    invitation: false,
    join: false,
    reservation: false,
  }

  if (persist) {
    const persistData = JSON.parse(persist)

    const { data: qrcodeData = false } = JSON.parse(persistData.qrcode)
    const { data: registrationData = false } = JSON.parse(persistData.registration)
    const { data: invitationData = false } = JSON.parse(persistData.invitation)
    const { data: joinData = false } = JSON.parse(persistData.join)
    const { data: reservationData = false } = JSON.parse(persistData.reservation)

    data.qrcode = qrcodeData
    data.registration = registrationData
    data.invitation = invitationData
    data.join = joinData
    data.reservation = reservationData
  }

  return data
}

export const clearData = () => {
  Cookies.remove(APP_ACCESS_TOKEN_KEY_NAME)
  Cookies.remove(USER_ACCESS_TOKEN_KEY_NAME)

  window.localStorage.clear()
  window.sessionStorage.clear()
}

export const clearCookies = () => {
  // Cookies.remove(APP_ACCESS_TOKEN_KEY_NAME)
  Cookies.remove(USER_ACCESS_TOKEN_KEY_NAME)
}
