import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import Cookies from 'js-cookie'

import { ObservableStates } from '../_redux/root-reducers'
import env from '../_configs/env'
import { APP_ACCESS_TOKEN_KEY_NAME } from '../_configs/token'
import {
  INVITATION_VERIFY,
  INVITATION_VERIFY_SUCCESS,
  INVITATION_VERIFY_FAILURE
} from '../states/invitation-state'
import { appLoaded } from '../actions/app-action'
import { invitationVerifySuccess, invitationVerifyFailure } from '../actions/invitation-action'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { InvitationResponseInterface } from '../interfaces/invitation-interface'
import { SessionResponseInterface } from '../interfaces/session-interface'

// noinspection JSUnusedGlobalSymbols
export function invitationVerifyService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface
) {
  return action$.pipe(
    ofType(INVITATION_VERIFY),
    mergeMap(() =>
      dependencies
        .baseClient({
          method: 'POST',
          url: '/customers/anon/sessions',
          auth: {
            username: env.apiClientId,
            password: env.apiClientSecret,
          },
        })
        .pipe(
          mergeMap(({ data: { data } }: SessionResponseInterface) => {
            const { token, expiredAt } = data.session
            const expires = new Date(0)

            expires.setUTCSeconds(expiredAt)

            Cookies.set(APP_ACCESS_TOKEN_KEY_NAME, token, { expires })

            return dependencies
              .baseClient({
                method: 'POST',
                url: '/customers/tables/invitations/verify',
                data: {
                  invitationCode: state$.value.invitation.invitationCode,
                  tableCode: state$.value.invitation.tableCode,
                },
              })
              .pipe(
                map(({ data: { data } }: InvitationResponseInterface) => invitationVerifySuccess(data)),
                catchError((error) => of(invitationVerifyFailure(error)))
              )
          }),
          catchError((error) => of(invitationVerifyFailure(error)))
        )
    ),
    catchError((error) => of(invitationVerifyFailure(error)))
  )
}

// noinspection JSUnusedGlobalSymbols
export function invitationVerifySuccessService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(INVITATION_VERIFY_SUCCESS),
    map(() => appLoaded())
  )
}

// noinspection JSUnusedGlobalSymbols
export function invitationVerifyFailureService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(INVITATION_VERIFY_FAILURE),
    map(() => appLoaded())
  )
}
