// ** Redux, Thunk & Root Reducer Imports
import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { history } from '../history'
import baseClient from '../_client/base-client'
import openClient from '../_client/open-client'

import rootEpics from './root-epics'
import rootReducers from './root-reducers'
import { PersistConfig } from 'redux-persist/es/types'

// ** init middleware
const epicMiddleware = createEpicMiddleware({
  dependencies: {
    history,
    baseClient,
    openClient,
  },
})
const middlewares = [routerMiddleware(history), thunk, epicMiddleware, createDebounce()]

// ** Dev Tools
let composeEnhancers = compose

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger({ collapsed: true }))

  // @ts-ignore
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined') {
    // @ts-ignore
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }
}

export const persistConfig: PersistConfig<any> = {
  storage,
  key: 'customer',
  keyPrefix: 'dash:',
  whitelist: [
    'qrcode',
    'registration',
    'invitation',
    'join',
    'reservation',
  ],
}

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducers)

// ** Create store
export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)))
export const persistor = persistStore(store)

epicMiddleware.run(rootEpics)
