// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // TODO: need specify method and baseUrl
  // '/customers/anon/sessions', // anonymous token
  // '/customers/tables/qr', // scan qr code
  // '/customers/tables/reserve', // submit table reservation
  // '/customers/tables/invitations/verify', // verify link invitation
  // '/customers/tables/invitations/join', // join invitation
  // '/customers/tables/help',
  // '/customers/menus', // list menu
  // '/customers/menu-categories', list category
  // '/customers/menus/menu1', // menu detail
  // '/customers/menus/menu2', // menu detail
  // '/customers/menus/menu3', // menu detail
  // '/customers/menus/menu4', // menu detail
  '/customers/menus/menu503', // maintenance error
  '/customers/menus/menu500', // internal error
  // '/customers/tables/carts', // cart
  // '/customers/orders/reviews', // review
  // '/invoices/xid-order', // invoices detail
  // '/customers/tables/orders/cash-payment', // payment cash
]
