import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { MenuResponseInterface } from '../interfaces/menu-interface'
import { appLoaded } from '../actions/app-action'
import { MENU_FETCH_API, MENU_FETCH_API_SUCCESS, MENU_FETCH_API_FAILURE } from '../states/menu-state'
import { menuFetchApiSuccess, menuFetchApiFailure } from '../actions/menu-action'

// noinspection JSUnusedGlobalSymbols
export function menuFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(MENU_FETCH_API),
    map(() => ({
      filters: {
        recommended: state$.value.menu.query.recommended,
        name: state$.value.menu.query.name,
        categoryXid: state$.value.menu.query.categoryXid,
      },
    })),
    mergeMap((params) =>
      dependencies
        .baseClient({
          params,
          method: 'GET',
          url: '/customers/menus',
        })
        .pipe(
          map(({ data: { data } }: MenuResponseInterface) => menuFetchApiSuccess(data)),
          catchError((error) => of(menuFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(menuFetchApiFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function menuFetchApiSuccessService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(MENU_FETCH_API_SUCCESS),
    map(() => appLoaded()),
  )
}

// noinspection JSUnusedGlobalSymbols
export function menuFetchApiFailureService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(MENU_FETCH_API_FAILURE),
    map(() => appLoaded()),
  )
}
