// eslint-disable-next-line import/no-anonymous-default-export
export default function (error: any) {
  if (error.response) {
    if (error.response.data) {
      return error.response.data
    }

    return error.response
  }

  return {
    error: {
      success: false,
      code: '500',
      data: null,
      message: 'Unknown Error',
    },
  }
}
