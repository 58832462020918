import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { InvoiceRecipientInterface, SummaryInterface } from '../interfaces/summary-interface'

export const SUMMARY_FETCH_API = 'SUMMARY_FETCH_API'
export const SUMMARY_FETCH_API_SUCCESS = 'SUMMARY_FETCH_API_SUCCESS'
export const SUMMARY_FETCH_API_FAILURE = 'SUMMARY_FETCH_API_FAILURE'

export const SUMMARY_INPUT = 'SUMMARY_INPUT'

export interface SummaryState {
  data?: SummaryInterface
  forms: InvoiceRecipientInterface
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: SummaryState = {
  error: {
    code: '',
    message: '',
  },
  forms: {
    email: '',
    phone: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [SUMMARY_FETCH_API]: (state: SummaryState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [SUMMARY_FETCH_API_SUCCESS]: (state: SummaryState, { payload }: { payload: SummaryState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [SUMMARY_FETCH_API_FAILURE]: (state: SummaryState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),

  [SUMMARY_INPUT]: (state: SummaryState, { payload }: { payload: { name: string; value: string } }) => ({
    ...state,
    forms: {
      ...state.forms,
      [payload.name]: payload.value,
    },
  }),
}

export default reducer(Object.assign(reducers), initialState)
