import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Offline, Online } from 'react-detect-offline'

import AlertConfirmation from './components/AlertConfirmation'
import NetworkErrorPage from './pages/error/NetworkErrorPage'
import ProgressBar from './components/ProgressBar'
import Toast from './components/Toast'
import {
  ProtectedRouteSummary,
  ProtectedRouteInvitation,
  ProtectedRouteJoin,
  ProtectedRouteQrcode,
  ProtectedRouteRegistration,
  ProtectedRouteReservation, ProtectedRouteReview,
} from './Router'
import routes, { Guards, Routes as RoutesInterface } from './routes'

function App() {
  return (
    <div className="dash-container sm:shadow-sm">
      <div>
        <Online>
          <BrowserRouter basename="/">
            <Suspense fallback={<ProgressBar isAnimating={true} />}>
              <Routes>
                {routes.map((route: RoutesInterface) => {
                  let Element

                  switch (route.guard) {
                    case Guards.QRCODE:
                      Element = (<ProtectedRouteQrcode view={route.view} />)
                      break
                    case Guards.REGISTRATION:
                      Element = (<ProtectedRouteRegistration view={route.view} />)
                      break
                    case Guards.INVITATION:
                      Element = (<ProtectedRouteInvitation view={route.view} />)
                      break
                    case Guards.JOIN:
                      Element = (<ProtectedRouteJoin view={route.view} />)
                      break
                    case Guards.RESERVATION:
                      Element = (<ProtectedRouteReservation view={route.view} />)
                      break
                    case Guards.SUMMARY:
                      Element = (<ProtectedRouteSummary view={route.view} />)
                      break
                    case Guards.REVIEW:
                      Element = (<ProtectedRouteReview view={route.view} />)
                      break
                    default:
                      const View = route.view
                      Element = (<View />)
                      break
                  }

                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={Element}
                    />
                  )
                })}
              </Routes>
            </Suspense>
          </BrowserRouter>
        </Online>

        <Offline>
          <NetworkErrorPage />
        </Offline>
      </div>

      <Toast />

      <AlertConfirmation />
    </div>
  )
}

export default App
