// eslint-disable-next-line import/no-anonymous-default-export
export default {
  url:
    `${window.location.protocol}//${window.location.hostname}` +
    (window.location.port ? `:${window.location.port}` : ''),
  environment: process.env.NODE_ENV,
  buildName: process.env.REACT_APP_BUILD_NAME || 'bn',

  platformId: Number(process.env.REACT_APP_PLATFORM_ID || 2),

  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  apiClientId: process.env.REACT_APP_API_CLIENT_ID || '',
  apiClientSecret: process.env.REACT_APP_API_CLIENT_SECRET || '',

  mockApiBaseUrl: process.env.REACT_APP_MOCK_API_BASE_URL || '',
  mockApiClientId: process.env.REACT_APP_MOCK_API_CLIENT_ID || '',
  mockApiClientSecret: process.env.REACT_APP_MOCK_API_CLIENT_SECRET || '',
}
