import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { ReviewResponseInterface } from '../interfaces/review-interface'
import { REVIEW_POST_API } from '../states/review-state'
import { postReviewApiFailure, postReviewApiSuccess } from '../actions/review-action'

// noinspection JSUnusedGlobalSymbols
export function menuDetailFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(REVIEW_POST_API),
    map(() => ({
      rating: state$.value.review.forms.rating,
      description: state$.value.review.forms.description,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/orders/reviews',
        })
        .pipe(
          map(({ data }: ReviewResponseInterface) => postReviewApiSuccess(data.success)),
          catchError((error) => of(postReviewApiFailure(error))),
        ),
    ),
    catchError((error) => of(postReviewApiFailure(error))),
  )
}
