import React, { memo } from 'react'
import clsxm from '../../_utils/clsxm'

interface Props {
  text: string
  type: 'button' | 'submit' | 'reset'
  isDisabled?: boolean
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

function ButtonOutlineDashBlue({ text, type, isDisabled, handleClick, className }: Props) {
  return (
    <button
      type={type}
      className={clsxm(
        'border-primary-blue text-primary-blue mx-auto w-full rounded-10px border bg-white py-4 text-center text-base',
        className,
        isDisabled && 'cursor-not-allowed text-gray-blue-2 border-text',
      )}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {text}
    </button>
  )
}

export default memo(ButtonOutlineDashBlue)
