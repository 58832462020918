import { ErrorInterface } from '../interfaces/commons-interface'
import { MenuDetailInterface } from '../interfaces/menu-detail-interface'
import {
  MENU_DETAIL_FETCH_API,
  MENU_DETAIL_FETCH_API_SUCCESS,
  MENU_DETAIL_FETCH_API_FAILURE
} from '../states/menu-detail-state'

export function menuDetailFetchApi(xid: string = '') {
  return {
    type: MENU_DETAIL_FETCH_API,
    payload: xid
  }
}

export function menuDetailFetchApiSuccess(data: MenuDetailInterface) {
  return {
    type: MENU_DETAIL_FETCH_API_SUCCESS,
    payload: { data },
  }
}

export function menuDetailFetchApiFailure(error: ErrorInterface) {
  return {
    type: MENU_DETAIL_FETCH_API_FAILURE,
    payload: error,
  }
}
