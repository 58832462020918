import React, { FC, LazyExoticComponent, VFC } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReservationEnum } from './_configs/constant'
import { States } from './_redux/root-reducers'
import { Guards, Routes as RoutesInterface } from './routes'
import {
  getQrcodeDataSelector,
  getQrcodeErrorSelector,
  QrcodeState
} from './states/qrcode-state'
import {
  getRegistrationDataSelector,
  getRegistrationErrorSelector,
  RegistrationState
} from './states/registration-state'
import {
  getInvitationDataSelector,
  getInvitationErrorSelector,
  InvitationState
} from './states/invitation-state'
import {
  getInvitationCodeDataSelector,
  getInvitationCodeErrorSelector,
  InvitationCodeState
} from './states/invitation-code-state'
import {
  getJoinDataSelector,
  getJoinErrorSelector,
  JoinState
} from './states/join-state'
import {
  getReservationMeDataSelector,
  getReservationMeErrorSelector,
  ReservationState
} from './states/reservation-state'
import Expired from './views/expired'
import Maintenance from './views/maintenance'
import Internal from './views/internal'

interface ProtectedRouteProps {
  view: LazyExoticComponent<() => JSX.Element> | LazyExoticComponent<FC> | LazyExoticComponent<VFC>
}

// Level Of Protection
// 1. Reservation
// 2. Registration (Main Customer)
// 2. Join (Additional Customer)
// 3. Qrcode (Main Customer)
// 4. Invitation (Additional Customer)

export const ProtectedRouteQrcode: VFC<ProtectedRouteProps> = ({ view: View }) => {
  // qrcode state
  // const qrcodeData = useSelector(getQrcodeDataSelector)
  const qrCodeError = useSelector(getQrcodeErrorSelector)
  // invitation state
  //const invitationData = useSelector(getInvitationDataSelector)

  //if (qrcodeData) {
  //  return (<Navigate to="/registration" replace={true} />)
  //} else if (invitationData) {
  //  return (<Navigate to="/join" replace={true} />)
  //}

  if (qrCodeError.code === 'E_503') {
    return (<Maintenance />)
  } else if (qrCodeError.code === 'E_500') {
    return (<Internal />)
  }

  return (<View />)
}

export const ProtectedRouteRegistration: VFC<ProtectedRouteProps> = ({ view: View }) => {
  // qrcode state
  const qrcodeData = useSelector(getQrcodeDataSelector)
  // registration state
  const registrationData = useSelector(getRegistrationDataSelector)
  const registrationError = useSelector(getRegistrationErrorSelector)
  // invitation state
  const invitationData = useSelector(getInvitationDataSelector)
  // invitation code state
  const invitationCodeData = useSelector(getInvitationCodeDataSelector)

  if (registrationData) {
    return (<Navigate to="/registered" replace={true} />)
  } else if (invitationData || invitationCodeData) {
    return (<Navigate to="/join" replace={true} />)
  } else if (registrationError.code === 'E_503') {
    return (<Maintenance />)
  } else if (registrationError.code === 'E_500') {
    return (<Internal />)
  } else if (!qrcodeData) {
    return (<Expired />)
  }

  return (<View />)
}

export const ProtectedRouteInvitation: VFC<ProtectedRouteProps> = ({ view: View }) => {
  // qrcode state
  //const qrcodeData = useSelector(getQrcodeDataSelector)
  // invitation state
  //const invitationData = useSelector(getInvitationDataSelector)
  const invitationError = useSelector(getInvitationErrorSelector)
  // invitation code state
  const invitationCodeError = useSelector(getInvitationCodeErrorSelector)

  //if (invitationData) {
  //  return (<Navigate to="/join" replace={true} />)
  //} else if (qrcodeData) {
  //  return (<Navigate to="/registration" replace={true} />)
  //}

  if (invitationError.code === 'E_503' || invitationCodeError.code === 'E_503') {
    return (<Maintenance />)
  } else if (invitationError.code === 'E_500' || invitationCodeError.code === 'E_500') {
    return (<Internal />)
  }

  return (<View />)
}

export const ProtectedRouteJoin: VFC<ProtectedRouteProps> = ({ view: View }) => {
  // qrcode state
  const qrcodeData = useSelector(getQrcodeDataSelector)
  // invitation state
  const invitationData = useSelector(getInvitationDataSelector)
  // invitation code state
  const invitationCodeData = useSelector(getInvitationCodeDataSelector)
  // join state
  const joinData = useSelector(getJoinDataSelector)
  const joinError = useSelector(getJoinErrorSelector)

  if (joinData) {
    return (<Navigate to="/home" replace={true} />)
  } else if (qrcodeData && !qrcodeData.table.isReserved) {
    return (<Navigate to="/registration" replace={true} />)
  } else if (joinError.code === 'E_503') {
    return (<Maintenance />)
  } else if (joinError.code === 'E_500') {
    return (<Internal />)
  } else if (!invitationData && !invitationCodeData) {
    return (<Expired />)
  }

  return (<View />)
}

export const ProtectedRouteReservation: VFC<ProtectedRouteProps> = ({ view: View }) => {
  // reservation state
  const reservationData = useSelector(getReservationMeDataSelector)

  if (reservationData) {
    if (reservationData.isMainCustomer) {
      if (reservationData.reservation.status === ReservationEnum.ClosedByMainCustomer) {
        return (<Navigate to="/summary" replace={true} />)
      } else if (reservationData.reservation.status === ReservationEnum.ClosedByCaptain) {
        return (<Navigate to="/closed" replace={true} />)
      }
    } else {
      if (reservationData.reservation.status !== ReservationEnum.Reserved) {
        return (<Expired clear />)
      }
    }
  } else if (!reservationData) {
    return (<Expired />)
  }

  return (<View />)
}

export const ProtectedRouteSummary: VFC<ProtectedRouteProps> = ({ view: View }) => {
  // reservation state
  const reservationData = useSelector(getReservationMeDataSelector)
  const reservationError = useSelector(getReservationMeErrorSelector)

  if (reservationData) {
    if (reservationData.isMainCustomer) {
      if (reservationData.reservation.status === ReservationEnum.ClosedByCaptain) {
        return (<Navigate to="/closed" replace={true} />)
      } else if (reservationData.reservation.status !== ReservationEnum.ClosedByMainCustomer) {
        return (<Navigate to="/home" replace={true} />)
      }
    } else {
      if (reservationData.reservation.status === ReservationEnum.Reserved) {
        return (<Navigate to="/home" replace={true} />)
      } else {
        return (<Expired clear />)
      }
    }
  } else if (reservationError.code === 'E_503') {
    return (<Maintenance />)
  } else if (reservationError.code === 'E_500') {
    return (<Internal />)
  } else if (!reservationData) {
    return (<Expired />)
  }

  return (<View />)
}

export const ProtectedRouteReview: VFC<ProtectedRouteProps> = ({ view: View }) => {
  // reservation state
  const reservationData = useSelector(getReservationMeDataSelector)

  if (reservationData) {
    if (reservationData.isMainCustomer) {
      if (reservationData.reservation.status === ReservationEnum.ClosedByMainCustomer) {
        return (<Navigate to="/summary" replace={true} />)
      } else if (reservationData.reservation.status !== ReservationEnum.ClosedByCaptain) {
        return (<Navigate to="/home" replace={true} />)
      }
    } else {
      if (reservationData.reservation.status === ReservationEnum.Reserved) {
        return (<Navigate to="/home" replace={true} />)
      } else {
        return (<Expired clear />)
      }
    }
  } else if (!reservationData) {
    return (<Expired />)
  }

  return (<View />)
}

function Router(route: RoutesInterface): JSX.Element {
  const { data: qrcodeData = undefined, error: qrcodeError } = useSelector<States, QrcodeState>((state) => state.qrcode)
  const { data: registrationData = undefined, error: registrationError } = useSelector<States, RegistrationState>((state) => state.registration)
  const { data: invitationData = undefined, error: invitationError } = useSelector<States, InvitationState>((state) => state.invitation)
  const { data: joinData = undefined, error: joinError } = useSelector<States, JoinState>((state) => state.join)
  const { data: reservationData = undefined } = useSelector<States, ReservationState>((state) => state.reservation)
  const { data: invitationCodeData = undefined, error: invitationCodeError } = useSelector<States, InvitationCodeState>((state) => state.invitationCode)

  const View = route.view

  if (qrcodeError.code === 'E_503' || registrationError.code === 'E_503' || invitationError.code === 'E_503' || joinError.code === 'E_503' || invitationCodeError.code === 'E_503') {
    return (<Maintenance />)
  } else if (qrcodeError.code === 'E_500' || registrationError.code === 'E_500' || invitationError.code === 'E_500' || joinError.code === 'E_500' || invitationCodeError.code === 'E_500') {
    return (<Internal />)
  }

  if (route.guard === Guards.QRCODE) {
    if (invitationData || invitationCodeData || joinData) { // redirect to join page when have invitation or join
      return (<Navigate to="/join" replace={true} />)
    } else if (registrationData || reservationData) { // redirect to registered page when have registration or reservation
      return (<Navigate to="/registered" replace={true} />)
    } else if (qrcodeData && !qrcodeData.table.isReserved) { // redirect to registration page when have qrcode
      return (<Navigate to="/registration" replace={true} />)
    }
  }

  if (route.guard === Guards.REGISTRATION) {
    if (invitationData || invitationCodeData || joinData) { // redirect to home page when have invitation or
      // invitation code or join
      return (<Navigate to="/home" replace={true} />)
    } else if (!qrcodeData) { // show expired page when don't have qrcode
      return (<Expired />)
    } else if (registrationData || reservationData) { // redirect to registered when have registration or reservation
      return (<Navigate to="/registered" replace={true} />)
    }
  }

  if (route.guard === Guards.INVITATION) {
    if ((qrcodeData && !qrcodeData.table.isReserved) || registrationData) {
      return (<Navigate to="/registration" replace={true} />)
    } else if (joinData || reservationData) {
      return (<Navigate to="/home" replace={true} />)
    } else if (invitationData || invitationCodeData) {
      return (<Navigate to="/join" replace={true} />)
    }
  }

  if (route.guard === Guards.JOIN) {
    if ((qrcodeData && !qrcodeData.table.isReserved) || registrationData) {
      return (<Navigate to="/registration" replace={true} />)
    } else if (!invitationData && !invitationCodeData) {
      return (<Expired />)
    } else if (joinData || reservationData) {
      return (<Navigate to="/home" replace={true} />)
    }
  }

  if (route.guard === Guards.RESERVATION) {
    if (reservationData) {
      if (reservationData.isMainCustomer) {
        if (reservationData.reservation.status === ReservationEnum.ClosedByMainCustomer) {
          return (<Navigate to="/summary" replace={true} />)
        }
      } else {
        if (reservationData.reservation.status !== ReservationEnum.Reserved) {
          return (<Expired />)
        }
      }
    } else if ((qrcodeData && !qrcodeData.table.isReserved) && registrationData) {
      return (<Navigate to="/registered" replace={true} />)
    } else if ((qrcodeData && !qrcodeData.table.isReserved) && (!joinData || !invitationCodeData || !invitationData)) {
      return (<Navigate to="/registration" replace={true} />)
    } else if (invitationData || invitationCodeData) {
      return (<Navigate to="/join" replace={true} />)
    } else {
      return (<Expired />)
    }
  }

  if (route.guard === Guards.SUMMARY) {
    if (reservationData && reservationData.isMainCustomer) {
      if (reservationData.reservation.status !== ReservationEnum.ClosedByMainCustomer) {
        return (<Navigate to="/home" replace={true} />)
      }
    } else {
      return (<Expired />)
    }
  }

  return (<View />)
}

export default Router
