import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAlertConfirmationSelector } from '../states/alert-confirmation-state'
import { alertConfirmationHide } from '../actions/alert-confirmation-action'
import baseClient from '../_client/base-client'
import ButtonDashBlue from './button/ButtonDashBlue'
import ButtonOutlineDashBlue from './button/ButtonOutlineDashBlue'

function AlertConfirmation() {
  const {
    show,
    title,
    confirmText,
    declineText,
    requestPayload,
    content: Content,
    onSuccess,
    onFailure,
    hideTitle = false,
    hideConfirmBtn = false,
    hideDeclineBtn = false,
  } = useSelector(getAlertConfirmationSelector)

  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch()

  const handleDecline = () => {
    dispatch(alertConfirmationHide())
  }

  const handleConfirm = async () => {
    setDisabled(true)

    const [response, error] = await baseClient(requestPayload, true)

    if (onSuccess && response) {
      onSuccess(response)
    }

    if (onFailure && error) {
      onFailure(error)
    }

    setDisabled(false)

    dispatch(alertConfirmationHide())
  }

  useEffect(() => {
    if (hideDeclineBtn && hideConfirmBtn) {
      setTimeout(() => {
        handleConfirm()
      }, 2000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideDeclineBtn, hideConfirmBtn])

  if (show) {
    return (
      <div className='fixed top-0 left-0 z-50 flex h-screen w-full items-center justify-center bg-black/50 bg-blend-darken'>
        <div className='mx-auto w-full max-w-2md p-4'>
          <div className='mx-auto h-full rounded-xl bg-white px-4 py-4'>
            {!hideTitle && <div className='mb-[25px] text-center text-lg font-bold text-blue-dark'>{title}</div>}
            {Content && <Content />}

            {(!hideConfirmBtn || !hideDeclineBtn) && (
              <div className='mt-[25px] flex w-full justify-start gap-2'>
                {!hideDeclineBtn && (
                  <ButtonOutlineDashBlue
                    type='button'
                    text={declineText}
                    isDisabled={disabled}
                    handleClick={handleDecline}
                  />
                )}

                {!hideConfirmBtn && (
                  <ButtonDashBlue
                    type='button'
                    text={confirmText}
                    isDisabled={disabled}
                    handleClick={handleConfirm}
                    className='mb-0'
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return <></>
}

export default memo(AlertConfirmation)
