interface Props {
  imageSrc: string
  errorText: string
  errorSubtitleText: string
  wrapperClass?: string
  children?: JSX.Element
}

function ErrorTemplate({ imageSrc, errorText, errorSubtitleText, wrapperClass, children }: Props) {
  return (
    <div className={`flex h-screen w-full items-center justify-center bg-blue-white-2 ${wrapperClass}`}>
      <div className='flex flex-col items-center'>
        <div className='mb-7.5 rounded-full bg-white p-[52px]'>
          <div className='h-123px w-123px'>
            <img alt='' src={imageSrc} className='h-full w-full object-contain' />
          </div>
        </div>
        <div className='mb-1 text-center text-lg font-medium text-blue-dark'>{errorText}</div>
        <div className='mb-7.5 text-center text-sm text-gray-blue'>{errorSubtitleText}</div>
        {children}
      </div>
    </div>
  )
}

export default ErrorTemplate
