import reducer from '../_utils/reducer'
import { States } from '../_redux/root-reducers'
import { AxiosRequestConfig } from 'axios'

export const ALERT_CONFIRMATION_SHOW = 'ALERT_CONFIRMATION_SHOW'
export const ALERT_CONFIRMATION_HIDE = 'ALERT_CONFIRMATION_HIDE'

export interface AlertConfirmationState {
  show: boolean
  title: string
  confirmText: string
  declineText: string
  requestPayload: AxiosRequestConfig
  content?: () => JSX.Element
  onSuccess?: (response: any) => void
  onFailure?: (error: any) => void
  hideTitle?: boolean
  hideConfirmBtn?: boolean
  hideDeclineBtn?: boolean
}

export const initialState: AlertConfirmationState = {
  show: false,
  confirmText: '',
  declineText: '',
  requestPayload: {},
  title: '',
}

export const reducers = {
  [ALERT_CONFIRMATION_SHOW]: (state: AlertConfirmationState, { payload }: { payload: AlertConfirmationState }) => ({
    ...state,
    show: true,
    title: payload.title,
    confirmText: payload.confirmText,
    declineText: payload.declineText,
    requestPayload: payload.requestPayload,
    content: payload.content,
    onSuccess: payload.onSuccess,
    onFailure: payload.onFailure,
    hideTitle: payload.hideTitle,
    hideConfirmBtn: payload.hideConfirmBtn,
    hideDeclineBtn: payload.hideDeclineBtn,
  }),
  [ALERT_CONFIRMATION_HIDE]: (state: AlertConfirmationState) => ({
    ...state,
    ...initialState,
  }),
}

export const getAlertConfirmationSelector = (state: States) => state.alertConfirmation

export default reducer(Object.assign(reducers), initialState)
