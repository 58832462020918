import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'
import { Observable } from 'rxjs'

import mockUrls from './mock-urls'
import env from '../_configs/env'
import { APP_ACCESS_TOKEN_KEY_NAME, USER_ACCESS_TOKEN_KEY_NAME } from '../_configs/token'

import errorHandling from './error-handling'

// eslint-disable-next-line import/no-anonymous-default-export
export default function (payload: AxiosRequestConfig, isPromise = false): Promise<any> | Observable<unknown> {
  const isMock = mockUrls.includes(payload.url as string)

  const api = axios.create({
    baseURL: isMock ? env.mockApiBaseUrl : env.apiBaseUrl,
  })

  payload.headers = {
    ...(payload.headers || {}),
  }

  if (payload.auth && isMock) {
    payload.auth.username = env.mockApiClientId
    payload.auth.password = env.mockApiClientSecret
  }

  if (payload.params) {
    payload.paramsSerializer = (params) => qs.stringify(params)
  }

  if (Cookies.get(USER_ACCESS_TOKEN_KEY_NAME)) {
    payload.headers.Authorization = `Bearer ${Cookies.get(USER_ACCESS_TOKEN_KEY_NAME)}`
  } else if (Cookies.get(APP_ACCESS_TOKEN_KEY_NAME)) {
    payload.headers.Authorization = `Bearer ${Cookies.get(APP_ACCESS_TOKEN_KEY_NAME)}`
  }

  if (isPromise) {
    return new Promise((resolve) => {
      return api.request(payload)
        .then((response) => {
          resolve([response, null])
        })
        .catch((error) => {
          console.error(error)

          resolve([null, errorHandling(error)])
        })
    })
  }

  return new Observable((subscriber): void => {
    api
      .request(payload)
      .then((response) => {
        subscriber.next(response)
        subscriber.complete()
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        subscriber.error(errorHandling(error))
      })
  })
}
