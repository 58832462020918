import { ErrorInterface } from '../interfaces/commons-interface'
import {
  REVIEW_POST_API,
  REVIEW_POST_API_SUCCESS,
  REVIEW_POST_API_FAILURE,
  REVIEW_POST_FORM,
} from '../states/review-state'

export function postReviewApi() {
  return {
    type: REVIEW_POST_API,
  }
}

export function postReviewApiSuccess(success: boolean) {
  return {
    type: REVIEW_POST_API_SUCCESS,
    payload: { success },
  }
}

export function postReviewApiFailure(error: ErrorInterface) {
  return {
    type: REVIEW_POST_API_FAILURE,
    payload: error,
  }
}

export function setPostReviewForm(rating: number, description: string) {
  return {
    type: REVIEW_POST_FORM,
    payload: { rating, description },
  }
}
