import reducer from '../_utils/reducer'

export const APP_LOADED = 'APP_LOADED'
export const APP_LOADING = 'APP_LOADING'

export interface AppState {
  isLoading: boolean
}

export const initialState: AppState = {
  isLoading: false,
}

export const reducers = {
  [APP_LOADED]: (state: AppState) => ({
    ...state,
    isLoading: false,
  }),
  [APP_LOADING]: (state: AppState) => ({
    ...state,
    isLoading: true,
  }),
}

export default reducer(Object.assign(reducers), initialState)
