import React from 'react'
import { AppState } from '../../../states/app-state'
import ProgressBar from '../../../components/ProgressBar'

interface BlankLayoutComponentProps {
  children: JSX.Element | boolean
  app: AppState
  handler: Record<any, any>
}

const BlankLayoutComponent: React.FC<BlankLayoutComponentProps> = (props) => {
  const { app, children } = props

  return (
    <>
      <ProgressBar isAnimating={app.isLoading} />

      <>{children}</>
    </>
  )
}

export default BlankLayoutComponent
