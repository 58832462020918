import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { FINISH_PAYMENT_CASH } from '../states/finish-payment-cash-state'
import { finishPaymentCashSuccess, finishPaymentCashFailure } from '../actions/finish-payment-cash-action'

// noinspection JSUnusedGlobalSymbols
export function finishPaymentCashService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(FINISH_PAYMENT_CASH),
    map(() => ({
      orderXid: state$.value.summary.data?.orderXid || '',
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/tables/orders/cash-payment/finish',
        })
        .pipe(
          map(() => finishPaymentCashSuccess()),
          catchError((error) => of(finishPaymentCashFailure(error))),
        ),
    ),
    catchError((error) => of(finishPaymentCashFailure(error))),
  )
}
