import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, debounceTime } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { POST_PAYMENT_CASH } from '../states/payment-cash-state'
import { paymentCashPostApiSuccess, paymentCashPostApiFailure } from '../actions/payment-cash-action'

// noinspection JSUnusedGlobalSymbols
export function menuFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(POST_PAYMENT_CASH),
    debounceTime(500),
    map(() => ({
      invoiceRecipientEmail: state$.value.summary.forms.email,
      invoiceRecipientPhone: state$.value.summary.forms.phone,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/tables/orders/cash-payment',
        })
        .pipe(
          map(() => paymentCashPostApiSuccess()),
          catchError((error) => of(paymentCashPostApiFailure(error))),
        ),
    ),
    catchError((error) => of(paymentCashPostApiFailure(error))),
  )
}
