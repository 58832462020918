import ErrorTemplate from '../../components/ErrorTemplate'
import Images from '../../assets/images/Images'

function Maintenance() {
  return (
    <ErrorTemplate
      imageSrc={Images.ErrorMaintenance}
      errorText='Sistem Dalam Pemeliharaan'
      errorSubtitleText='Mohon maaf, transaksi Anda terganggu. Pemeliharaan tidak akan memakan
           waktu lama. Silakan coba lagi dalam beberapa saat.'
      wrapperClass='px-4'
    />
  )
}

export default Maintenance