import { ErrorInterface } from '../interfaces/commons-interface'
import { CartOrderFormInterface, CartInterface } from '../interfaces/cart-interface'
import {
  CART_FETCH_API,
  CART_FETCH_API_SUCCESS,
  CART_FETCH_API_FAILURE,
  CART_POST_API,
  CART_PATCH_API,
  CART_DELETE_API,
  CART_SUBMIT_ORDER,
  CART_SUBMIT_ORDER_SUCCESS,
  CART_TOTAL_ITEMS,
  CART_TOTAL_ITEMS_SUCCESS,
  CART_SHOW_NOTES,
  CART_HIDE_NOTES,
  CART_NOTES_INPUT,
} from '../states/cart-state'

export function cartFetchApi() {
  return {
    type: CART_FETCH_API,
  }
}

export function cartFetchApiSuccess(data: CartInterface) {
  return {
    type: CART_FETCH_API_SUCCESS,
    payload: { data },
  }
}

export function cartFetchApiFailure(error: ErrorInterface) {
  return {
    type: CART_FETCH_API_FAILURE,
    payload: error,
  }
}

export function cartShowNotes(xid: string, notes: string = '', quantityOrdered: number) {
  return {
    type: CART_SHOW_NOTES,
    payload: { xid, notes, quantityOrdered },
  }
}

export function cartHideNotes() {
  return {
    type: CART_HIDE_NOTES,
  }
}

export function cartInputNotes(notes: string = '') {
  return {
    type: CART_NOTES_INPUT,
    payload: { notes },
  }
}

export function cartPostApi(data: CartOrderFormInterface) {
  return {
    type: CART_POST_API,
    payload: data,
  }
}

export function cartPatchApi(xid: string, quantityOrdered: number, notes: string) {
  return {
    type: CART_PATCH_API,
    payload: { xid, quantityOrdered, notes },
  }
}

export function cartDeleteApi(xid: string) {
  return {
    type: CART_DELETE_API,
    payload: { xid },
  }
}

export function cartFetchTotalItems() {
  return {
    type: CART_TOTAL_ITEMS,
  }
}

export function cartFetchTotalItemsSuccess(items: number) {
  return {
    type: CART_TOTAL_ITEMS_SUCCESS,
    payload: { items },
  }
}

export function cartSubmitOrder(signature: string) {
  return {
    type: CART_SUBMIT_ORDER,
    payload: { signature },
  }
}

export function cartSubmitOrderSuccess(success: boolean) {
  return {
    type: CART_SUBMIT_ORDER_SUCCESS,
    payload: { success },
  }
}
