import { ErrorInterface } from '../interfaces/commons-interface'
import { InvoiceInterface } from '../interfaces/invoice-interface'
import { INVOICE_FETCH_API, INVOICE_FETCH_API_SUCCESS, INVOICE_FETCH_API_FAILURE } from '../states/invoice-state'

export function invoiceFetchApi(xid: string) {
  return {
    type: INVOICE_FETCH_API,
    payload: { xid },
  }
}

export function invoiceFetchApiSuccess(data: InvoiceInterface) {
  return {
    type: INVOICE_FETCH_API_SUCCESS,
    payload: { data },
  }
}

export function invoiceFetchApiFailure(error: ErrorInterface) {
  return {
    type: INVOICE_FETCH_API_FAILURE,
    payload: error,
  }
}
