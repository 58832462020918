import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { MenuInterface, MenuQueryInterface } from '../interfaces/menu-interface'

export const MENU_SET_QUERY = 'MENU_SET_QUERY'

export const MENU_FETCH_API = 'MENU_FETCH_API'
export const MENU_FETCH_API_SUCCESS = 'MENU_FETCH_API_SUCCESS'
export const MENU_FETCH_API_FAILURE = 'MENU_FETCH_API_FAILURE'

export interface MenuState {
  data?: MenuInterface
  error: ErrorInterface
  query: MenuQueryInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: MenuState = {
  error: {
    code: '',
    message: '',
  },
  query: {
    name: '',
    recommended: '',
    categoryXid: ''
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false
}

export const reducers = {
  [MENU_SET_QUERY]: (state: MenuState, { payload }: { payload: MenuQueryInterface }) => ({
    ...state,
    query: {
      ...state.query,
      name: payload.name,
      recommended: payload.recommended,
      categoryXid: payload.categoryXid
    }
  }),
  [MENU_FETCH_API]: (state: MenuState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false
  }),
  [MENU_FETCH_API_SUCCESS]: (state: MenuState, { payload }: { payload: MenuState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [MENU_FETCH_API_FAILURE]: (state: MenuState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export default reducer(Object.assign(reducers), initialState)
