import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { States } from '../../_redux/root-reducers'

import BlankLayoutComponent from './component'

function mapStateToProps(state: States) {
  return {
    app: state.app,
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    handler: {},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlankLayoutComponent)
