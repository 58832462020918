const Icons = {
  share: require('./share.svg').default,
  bantuan: require('./desk_bell.svg').default,
  whatsapp: require('./whatsapp.svg').default,
  copy: require('./copy.svg').default,
  home: require('./home.svg').default,
  homeUn: require('./home_unselected.svg').default,
  shoppingBag: require('./shopping_bag.svg').default,
  shoppingBagUn: require('./shopping_bag_unselected.svg').default,
  chevronLeft: require('./chevron_left.svg').default,
  chevronLeftGray: require('./chevron_left_gray.svg').default,
  panas: require('./panas.svg').default,
  sync: require('./sync.svg').default,
  note: require('./note.svg').default,
  email: require('./email.svg').default,
  phone: require('./phone.svg').default,
  done: require('./done.svg').default,
  doneGreen: require('./done_green.svg').default,
  star: require('./star.svg').default,
  starOutline: require('./star_outline.svg').default,
  cart: require('./cart.svg').default,
  hatChef: require('./hat_chef.svg').default,
  leaf: require('./leaf.svg').default,
  meja: require('./meja.svg').default,
  logoPrimary: require('./logo_primary.svg').default,
  timelapse: require('./timelapse.svg').default,
  expandMore: require('./expand_more.svg').default,
  expandLess: require('./expand_less.svg').default,
  nonHalal: require('./non_halal.svg').default,
  pepperHot: require('./pepper_hot.svg').default,
}

export default Icons
