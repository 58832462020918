import { useNavigate } from 'react-router-dom'

import ErrorTemplate from '../../components/ErrorTemplate'
import Images from '../../assets/images/Images'

function Internal() {
  const navigate = useNavigate()

  return (
    <ErrorTemplate
      imageSrc={Images.ErrorInternal}
      errorText='Ada Masalah di Sistem Kami'
      errorSubtitleText='Mohon maaf, transaksi Anda terganggu. Kami berusaha memperbaikinya
           secepat mungkin. Silakan coba lagi dalam beberapa saat.'
      wrapperClass='px-4'>
      <button
        onClick={() => navigate(0)}
        className='bg-primary-blue w-full rounded-10px py-4 text-center text-base text-white'>
        Refresh Halaman
      </button>
    </ErrorTemplate>
  )
}

export default Internal
