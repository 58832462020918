import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { MenuDetailInterface } from '../interfaces/menu-detail-interface'

export const MENU_DETAIL_FETCH_API = 'MENU_DETAIL_FETCH_API'
export const MENU_DETAIL_FETCH_API_SUCCESS = 'MENU_DETAIL_FETCH_API_SUCCESS'
export const MENU_DETAIL_FETCH_API_FAILURE = 'MENU_DETAIL_FETCH_API_FAILURE'

export interface MenuDetailState {
  data?: MenuDetailInterface
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: MenuDetailState = {
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [MENU_DETAIL_FETCH_API]: (state: MenuDetailState, { payload }: { payload: string }) => ({
    ...state,
    data: {
      ...state.data,
      xid: payload,
    },
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [MENU_DETAIL_FETCH_API_SUCCESS]: (state: MenuDetailState, { payload }: { payload: MenuDetailState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [MENU_DETAIL_FETCH_API_FAILURE]: (state: MenuDetailState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export default reducer(Object.assign(reducers), initialState)
