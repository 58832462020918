import { ErrorInterface } from '../interfaces/commons-interface'
import {
  POST_PAYMENT_CASH,
  POST_PAYMENT_CASH_SUCCESS,
  POST_PAYMENT_CASH_FAILURE,
} from '../states/payment-cash-state'

export function paymentCashPostApi() {
  return {
    type: POST_PAYMENT_CASH,
  }
}

export function paymentCashPostApiSuccess() {
  return {
    type: POST_PAYMENT_CASH_SUCCESS,
  }
}

export function paymentCashPostApiFailure(error: ErrorInterface) {
  return {
    type: POST_PAYMENT_CASH_FAILURE,
    payload: { error },
  }
}
