import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { InvoiceInterface } from '../interfaces/invoice-interface'

export const INVOICE_FETCH_API = 'INVOICE_FETCH_API'
export const INVOICE_FETCH_API_SUCCESS = 'INVOICE_FETCH_API_SUCCESS'
export const INVOICE_FETCH_API_FAILURE = 'INVOICE_FETCH_API_FAILURE'

export interface InvoiceState {
  data?: InvoiceInterface
  error: ErrorInterface
  xid?: string
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: InvoiceState = {
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [INVOICE_FETCH_API]: (state: InvoiceState, { payload }: { payload: { xid: string } }) => ({
    ...state,
    xid: payload.xid,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [INVOICE_FETCH_API_SUCCESS]: (state: InvoiceState, { payload }: { payload: InvoiceState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [INVOICE_FETCH_API_FAILURE]: (state: InvoiceState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export default reducer(Object.assign(reducers), initialState)
