import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { HELP } from '../states/help-state'
import { helpFailure, helpSuccess } from '../actions/help-action'

// noinspection JSUnusedGlobalSymbols
export function helpService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface
) {
  return action$.pipe(
    ofType(HELP),
    mergeMap((data) =>
      dependencies
        .baseClient({
          method: 'POST',
          url: `/customers/tables/${state$.value.qrcode.data?.table.xid || state$.value.join.data?.table.xid}/help`,
        })
        .pipe(
          map(() => helpSuccess()),
          catchError((error) => of(helpFailure(error)))
        )
    ),
    catchError((error) => of(helpFailure(error)))
  )
}
