import reducer from '../_utils/reducer'
import { ErrorInterface, InputInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { InvitationInterface } from '../interfaces/invitation-interface'
import { States } from '../_redux/root-reducers'

export const INVITATION_INPUT = 'INVITATION_INPUT'
export const INVITATION_SET_CODE = 'INVITATION_SET_CODE'

export const INVITATION_VERIFY = 'INVITATION_VERIFY'
export const INVITATION_VERIFY_SUCCESS = 'INVITATION_VERIFY_SUCCESS'
export const INVITATION_VERIFY_FAILURE = 'INVITATION_VERIFY_FAILURE'

export interface InvitationState {
  invitationCode: string
  tableCode: string
  data?: InvitationInterface
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: InvitationState = {
  invitationCode: '',
  tableCode: '',
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [INVITATION_INPUT]: (state: InvitationState, { payload }: { payload: InputInterface }) => ({
    ...state,
    [payload.name]: payload.value,
  }),
  [INVITATION_SET_CODE]: (state: InvitationState, { payload }: { payload: InvitationState }) => ({
    ...state,
    invitationCode: payload.invitationCode,
    tableCode: payload.tableCode,
  }),

  [INVITATION_VERIFY]: (state: InvitationState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [INVITATION_VERIFY_SUCCESS]: (state: InvitationState, { payload }: { payload: InvitationState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [INVITATION_VERIFY_FAILURE]: (state: InvitationState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export const getInvitationSelector = (states: States) => states.invitation
export const getInvitationIsLoadingSelector = (states: States) => states.invitation.isLoading
export const getInvitationIsSuccessSelector = (states: States) => states.invitation.isSuccess
export const getInvitationIsFailureSelector = (states: States) => states.invitation.isFailure
export const getInvitationErrorSelector = (states: States) => states.invitation.error
export const getInvitationDataSelector = (states: States) => states.invitation.data

export default reducer(Object.assign(reducers), initialState)
