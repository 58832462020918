// eslint-disable-next-line import/no-anonymous-default-export
export default function (reducers: any, states: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (state = states, { type, payload }) => {
    const reduce = reducers[type]

    if (!reduce) {
      return state
    }

    return reduce(state, { type, payload })
  }
}
