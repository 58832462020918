import { APP_LOADED, APP_LOADING } from '../states/app-state'

export function appLoading() {
  return {
    type: APP_LOADING,
  }
}

export function appLoaded() {
  return {
    type: APP_LOADED,
  }
}
