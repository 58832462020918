import { ErrorInterface } from '../interfaces/commons-interface'
import {MenuInterface, MenuQueryInterface} from '../interfaces/menu-interface'
import {
  MENU_SET_QUERY,

  MENU_FETCH_API,
  MENU_FETCH_API_SUCCESS,
  MENU_FETCH_API_FAILURE
} from '../states/menu-state'

export function menuSetQuery(query: MenuQueryInterface){
  return {
    type: MENU_SET_QUERY,
    payload: query
  }
}

export function menuFetchApi(){
  return {
    type: MENU_FETCH_API
  }
}

export function menuFetchApiSuccess(data: MenuInterface){
  return {
    type: MENU_FETCH_API_SUCCESS,
    payload: { data }
  }
}

export function menuFetchApiFailure(error: ErrorInterface) {
  return {
    type: MENU_FETCH_API_FAILURE,
    payload: error
  }
}
