import React from 'react'
import clsxm from '../../_utils/clsxm'

interface Props {
  text: string
  type: 'button' | 'submit' | 'reset'
  isDisabled?: boolean
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

function ButtonDashBlue({ text, type, isDisabled, handleClick, className }: Props) {
  return (
    <button
      type={type}
      className={clsxm(
        'bg-primary-blue mx-auto mb-4 w-full rounded-10px py-4 text-center text-base text-white',
        className,
        isDisabled && 'cursor-not-allowed bg-gray-blue-2',
      )}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {text}
    </button>
  )
}

export default ButtonDashBlue
