import { ErrorInterface } from '../interfaces/commons-interface'
import { ReservationInterface } from '../interfaces/reservation-interface'
import { RESERVATION_ME_API, RESERVATION_ME_API_FAILURE, RESERVATION_ME_API_SUCCESS } from '../states/reservation-state'

export function reservationMeApi() {
  return {
    type: RESERVATION_ME_API,
  }
}

export function reservationMeApiSuccess(data: ReservationInterface) {
  return {
    type: RESERVATION_ME_API_SUCCESS,
    payload: { data },
  }
}

export function reservationMeApiFailure(error: ErrorInterface) {
  return {
    type: RESERVATION_ME_API_FAILURE,
    payload: error,
  }
}
