import React from 'react'

function NetworkErrorPage() {
  return (
    <div className='absolute top-0 z-[9999] flex h-screen w-full items-center justify-center bg-blue-white-2'>
      <div className='flex flex-col items-center'>
        <div className='mb-7.5 rounded-full bg-white p-[52px]'>
          <div className='h-123px w-123px'>
            <div className='h-full w-full object-contain'>
              <svg
                width='123'
                height='123'
                viewBox='0 0 123 123'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g clip-path='url(#clip0_413_15938)'>
                  <path
                    d='M121.008 106.507C126.669 92.8499 120.187 77.1897 106.53 71.5289C92.8729 65.868 77.2127 72.3502 71.5518 86.0072C65.8909 99.6642 72.3731 115.324 86.0301 120.985C99.6871 126.646 115.347 120.164 121.008 106.507Z'
                    fill='#FF9ECE'
                  />
                  <path
                    d='M96.2379 69.4761C110.995 69.4761 123 81.4815 123 96.2382C123 110.995 110.995 123 96.2379 123V69.4761Z'
                    fill='#FC5896'
                  />
                  <path
                    d='M104.244 99.1487L101.334 96.2383L104.244 93.3278C105.652 91.9205 105.652 89.639 104.244 88.2317C102.837 86.8244 100.556 86.8244 99.1483 88.2317L96.2379 91.1422L93.3274 88.2317C91.9201 86.8244 89.6386 86.8244 88.2313 88.2317C86.824 89.639 86.824 91.9205 88.2313 93.3278L91.1418 96.2383L88.2313 99.1487C86.824 100.556 86.824 102.838 88.2313 104.245C89.6386 105.652 91.9201 105.652 93.3274 104.245L96.2379 101.334L99.1483 104.245C100.556 105.652 102.837 105.652 104.244 104.245C105.652 102.838 105.652 100.556 104.244 99.1487Z'
                    fill='#F3F9FF'
                  />
                  <path
                    d='M96.2379 101.334L99.1484 104.245C100.556 105.652 102.837 105.652 104.244 104.245C105.652 102.838 105.652 100.556 104.244 99.1487L101.334 96.2383L104.244 93.3278C105.652 91.9205 105.652 89.639 104.244 88.2317C102.837 86.8244 100.556 86.8244 99.1484 88.2317L96.2379 91.1422V101.334Z'
                    fill='#E1EEFF'
                  />
                  <path
                    d='M25.5196 43.8841C21.0512 48.3525 21.0512 55.597 25.5196 60.0654C29.988 64.5337 37.2325 64.5337 41.7008 60.0654C49.4758 52.2904 62.0814 52.2904 69.8563 60.0654C74.3247 64.5337 81.5692 64.5337 86.0375 60.0654C90.5059 55.597 90.5059 48.3525 86.0375 43.8841C69.3259 27.1727 42.231 27.1727 25.5196 43.8841Z'
                    fill='#60C7FF'
                  />
                  <path
                    d='M108.205 21.7156C79.2502 -7.23917 32.3052 -7.23917 3.35026 21.7156C-1.1181 26.1839 -1.1181 33.4284 3.35026 37.8968C7.81862 42.3651 15.0631 42.3651 19.5315 37.8968C39.5497 17.8785 72.0056 17.8785 92.0237 37.8968C96.492 42.3651 103.737 42.3651 108.205 37.8968C112.673 33.4284 112.673 26.1839 108.205 21.7156Z'
                    fill='#60C7FF'
                  />
                  <path
                    d='M63.854 82.231C68.3216 77.7634 68.3216 70.5198 63.854 66.0522C59.3863 61.5845 52.1428 61.5845 47.6752 66.0522C43.2075 70.5198 43.2075 77.7634 47.6752 82.231C52.1428 86.6987 59.3863 86.6987 63.854 82.231Z'
                    fill='#60C7FF'
                  />
                  <path
                    d='M86.0373 60.0656C90.5056 55.5972 90.5056 48.3527 86.0373 43.8843C77.6814 35.5285 66.7299 31.3506 55.7783 31.3506V54.2341C60.8734 54.2341 65.9685 56.1778 69.856 60.0653C74.3246 64.5339 81.5691 64.5339 86.0373 60.0656Z'
                    fill='#2585FD'
                  />
                  <path
                    d='M108.206 37.8972C112.674 33.4289 112.674 26.1843 108.206 21.716C93.7284 7.23874 74.7535 0 55.7786 0V22.8835C68.897 22.8835 82.0155 27.8881 92.0246 37.8972C96.493 42.3656 103.738 42.3656 108.206 37.8972Z'
                    fill='#2585FD'
                  />
                  <path
                    d='M67.2202 74.1431C67.2202 67.8239 62.0974 62.7012 55.7783 62.7012V85.5847C62.0977 85.5849 67.2204 80.4622 67.2202 74.1431Z'
                    fill='#2585FD'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_413_15938'>
                    <rect
                      width='123'
                      height='123'
                      fill='white'
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className='mb-1 text-center text-lg font-medium text-blue-dark'>Tidak Ada Koneksi Internet</div>
        <div className='mb-7.5 text-center text-sm text-gray-blue'>Refresh Halaman</div>

        <button
          onClick={() => {
            window.location.reload()
          }}
          className='bg-primary-blue w-full rounded-10px py-4 text-center text-base text-white'
        >
          Refresh Halaman
        </button>
      </div>
    </div>
  )
}

export default NetworkErrorPage
