import { ALERT_CONFIRMATION_HIDE, ALERT_CONFIRMATION_SHOW } from '../states/alert-confirmation-state'
import { AxiosRequestConfig } from 'axios'

export function alertConfirmationShow(
  title: string,
  confirmText: string,
  declineText: string,
  requestPayload: AxiosRequestConfig,
  content: (() => JSX.Element) | undefined = undefined,
  onSuccess: ((response: any) => void) | undefined = undefined,
  onFailure: ((error: any) => void) | undefined = undefined,
  hideTitle?: boolean,
  hideConfirmBtn?: boolean,
  hideDeclineBtn?: boolean,
) {
  return {
    type: ALERT_CONFIRMATION_SHOW,
    payload: {
      title,
      confirmText,
      declineText,
      requestPayload,
      content,
      onSuccess,
      onFailure,
      hideTitle,
      hideConfirmBtn,
      hideDeclineBtn,
    },
  }
}

export function alertConfirmationHide() {
  return {
    type: ALERT_CONFIRMATION_HIDE,
  }
}
