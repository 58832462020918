import { ErrorInterface } from '../interfaces/commons-interface'
import { HELP, HELP_SUCCESS, HELP_FAILURE } from '../states/help-state'

export function help() {
  return {
    type: HELP,
  }
}

export function helpSuccess() {
  return {
    type: HELP_SUCCESS,
  }
}

export function helpFailure(error: ErrorInterface) {
  return {
    type: HELP_FAILURE,
    payload: error,
  }
}
