import { ErrorInterface } from '../interfaces/commons-interface'
import { JoinInterface } from '../interfaces/join-interface'
import {
  JOIN_INPUT,
  JOIN_SUBMIT,
  JOIN_SUBMIT_SUCCESS,
  JOIN_SUBMIT_FAILURE,
} from '../states/join-state'

export function joinInput(name: string, value: string) {
  return {
    type: JOIN_INPUT,
    payload: { name, value },
  }
}

export function joinSubmit() {
  return {
    type: JOIN_SUBMIT,
  }
}

export function joinSuccess(data: JoinInterface) {
  return {
    type: JOIN_SUBMIT_SUCCESS,
    payload: { data },
  }
}

export function joinSubmitFailure(error: ErrorInterface) {
  return {
    type: JOIN_SUBMIT_FAILURE,
    payload: error,
  }
}
