import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'

export const POST_PAYMENT_CASH = 'POST_PAYMENT_CASH'
export const POST_PAYMENT_CASH_SUCCESS = 'POST_PAYMENT_CASH_SUCCESS'
export const POST_PAYMENT_CASH_FAILURE = 'POST_PAYMENT_CASH_FAILURE'

export interface PaymentCashState {
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: PaymentCashState = {
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [POST_PAYMENT_CASH]: (state: PaymentCashState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [POST_PAYMENT_CASH_SUCCESS]: (state: PaymentCashState) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
  }),
  [POST_PAYMENT_CASH_FAILURE]: (state: PaymentCashState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export default reducer(Object.assign(reducers), initialState)
