import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { CartResponseInterface } from '../interfaces/cart-interface'
import {
  CART_POST_API,
  CART_FETCH_API,
  CART_PATCH_API,
  CART_DELETE_API,
  CART_SUBMIT_ORDER,
  CART_TOTAL_ITEMS,
} from '../states/cart-state'
import {
  cartFetchApiSuccess,
  cartFetchApiFailure,
  cartSubmitOrderSuccess,
  cartFetchTotalItemsSuccess,
} from '../actions/cart-action'

// noinspection JSUnusedGlobalSymbols
export function cartFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(CART_FETCH_API),
    mergeMap((data) =>
      dependencies
        .baseClient({
          method: 'GET',
          url: '/customers/tables/carts',
        })
        .pipe(
          map(({ data: { data } }: CartResponseInterface) => cartFetchApiSuccess(data)),
          catchError((error) => of(cartFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(cartFetchApiFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function cartPostApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(CART_POST_API),
    map(() => ({
      xid: state$.value.cart.forms?.xid,
      variants: state$.value.cart.forms?.variants,
      additionals: state$.value.cart.forms?.additionals,
      quantityOrdered: state$.value.cart.forms?.quantityOrdered,
      notes: state$.value.cart.forms?.notes,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/tables/carts',
        })
        .pipe(
          map(({ data: { data } }: CartResponseInterface) => cartFetchApiSuccess(data)),
          catchError((error) => of(cartFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(cartFetchApiFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function cartPutApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(CART_PATCH_API),
    map(() => ({
      xid: state$.value.cart.forms?.xid,
      quantityOrdered: state$.value.cart.forms?.quantityOrdered,
      notes: state$.value.cart.forms?.notes,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'PATCH',
          url: `/customers/tables/carts/${data.xid}`,
        })
        .pipe(
          mergeMap((data) =>
            dependencies
              .baseClient({
                method: 'GET',
                url: '/customers/tables/carts',
              })
              .pipe(
                map(({ data: { data } }: CartResponseInterface) => cartFetchApiSuccess(data)),
                catchError((error) => of(cartFetchApiFailure(error))),
              ),
          ),
          catchError((error) => of(cartFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(cartFetchApiFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function cartDeleteApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(CART_DELETE_API),
    map(() => ({
      xid: state$.value.cart.forms?.xid,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          method: 'DELETE',
          url: `/customers/tables/carts/${data.xid}`,
        })
        .pipe(
          mergeMap((data) =>
            dependencies
              .baseClient({
                method: 'GET',
                url: '/customers/tables/carts',
              })
              .pipe(
                map(({ data: { data } }: CartResponseInterface) => cartFetchApiSuccess(data)),
                catchError((error) => of(cartFetchApiFailure(error))),
              ),
          ),
          catchError((error) => of(cartFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(cartFetchApiFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function cartSubmitOrderService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(CART_SUBMIT_ORDER),
    map(() => ({
      signature: state$.value.cart.forms?.signature,
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/tables/orders',
        })
        .pipe(
          map(
            ({
              data: {
                data: { success },
              },
            }) => cartSubmitOrderSuccess(success),
          ),
          catchError((error) => of(cartFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(cartFetchApiFailure(error))),
  )
}

// noinspection JSUnusedGlobalSymbols
export function cartFetchTotalItemsService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(CART_TOTAL_ITEMS),
    mergeMap((data) =>
      dependencies
        .baseClient({
          method: 'GET',
          url: '/customers/tables/carts/metadata',
        })
        .pipe(
          map(
            ({
              data: {
                data: { totalItem },
              },
            }) => cartFetchTotalItemsSuccess(totalItem),
          ),
          catchError((error) => of(cartFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(cartFetchApiFailure(error))),
  )
}
