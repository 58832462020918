import { ErrorInterface } from '../interfaces/commons-interface'
import { InvitationCodeInterface } from '../interfaces/invitation-code-interface'
import {
  INVITATION_CODE_VERIFY,
  INVITATION_CODE_VERIFY_SUCCESS,
  INVITATION_CODE_VERIFY_FAILURE,

  INVITATION_CODE_INPUT_CODE
} from '../states/invitation-code-state'

export function invitationCodeVerify(tableXid: string) {
  return {
    type: INVITATION_CODE_VERIFY,
    payload: { tableXid }
  }
}

export function invitationCodeVerifySuccess(data: InvitationCodeInterface) {
  return {
    type: INVITATION_CODE_VERIFY_SUCCESS,
    payload: { data }
  }
}

export function invitationCodeVerifyFailure(error: ErrorInterface) {
  return {
    type: INVITATION_CODE_VERIFY_FAILURE,
    payload: { error }
  }
}

export function invitationCodeInputCode(tableCode: string) {
  return {
    type: INVITATION_CODE_INPUT_CODE,
    payload: { tableCode }
  }
}
