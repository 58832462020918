import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { INVITATION_CODE_VERIFY } from '../states/invitation-code-state'
import { invitationCodeVerifySuccess, invitationCodeVerifyFailure } from '../actions/invitation-code-action'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { InvitationCodeResponseInterface } from '../interfaces/invitation-code-interface'

// noinspection JSUnusedGlobalSymbols
export function invitationCodeVerifyService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface
) {
  return action$.pipe(
    ofType(INVITATION_CODE_VERIFY),
    map(() => ({
      tableCode: state$.value.invitationCode.forms.tableCode,
      tableXid: state$.value.invitationCode.forms.tableXid
    })),
    mergeMap((data) =>
      dependencies
        .baseClient({
          data,
          method: 'POST',
          url: '/customers/tables/verify-code',
        })
        .pipe(
          map(({ data: { data } }: InvitationCodeResponseInterface) => invitationCodeVerifySuccess(data)),
          catchError((error) => of(invitationCodeVerifyFailure(error)))
        )
    ),
    catchError((error) => of(invitationCodeVerifyFailure(error)))
  )
}
