import reducer from '../_utils/reducer'

export const TOAST_SHOW = 'TOAST_SHOW'
export const TOAST_HIDE = 'TOAST_HIDE'

export interface ToastState {
  show: boolean
  type: 'success' | 'error'
  message: string
}

export const initialState: ToastState = {
  show: false,
  type: 'success',
  message: '',
}

export const reducers = {
  [TOAST_SHOW]: (state: ToastState, { payload }: { payload: ToastState }) => ({
    ...state,
    show: true,
    type: payload.type,
    message: payload.message,
  }),
  [TOAST_HIDE]: (state: ToastState) => ({
    ...state,
    show: false,
    message: '',
  }),
}

export default reducer(Object.assign(reducers), initialState)
