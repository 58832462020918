import React, { useEffect, VFC } from 'react'
import { useDispatch } from 'react-redux'

import { clearCookies } from '../../_utils/user'
import BlankLayout from '../../layouts/BlankLayout'
import ErrorTemplate from '../../components/ErrorTemplate'
import Images from '../../assets/images/Images'

interface Props {
  clear?: boolean
}

const Expired: VFC<Props> = ({ clear = false }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (clear) {
      clearCookies()

      dispatch({ type: 'CLEAR_DATA' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear])

  return (
    <BlankLayout>
      <ErrorTemplate
        imageSrc={Images.ErrorLinkExpired}
        errorText="Link Telah Kadaluarsa"
        errorSubtitleText="Link yang Anda tuju telah kedaluarsa"
        wrapperClass="px-4"
      />
    </BlankLayout>
  )
}

export default Expired
