import { QRCODE_VALIDATION, QRCODE_VALIDATION_FAILURE, QRCODE_VALIDATION_SUCCESS } from '../states/qrcode-state'
import { ErrorInterface } from '../interfaces/commons-interface'
import { QrcodeInterface } from '../interfaces/qrcode-interface'

export function qrcodeValidation(code: string) {
  return {
    type: QRCODE_VALIDATION,
    payload: { code },
  }
}

export function qrcodeValidationSuccess(data: QrcodeInterface) {
  return {
    type: QRCODE_VALIDATION_SUCCESS,
    payload: { data },
  }
}

export function qrcodeValidationFailure(error: ErrorInterface) {
  return {
    type: QRCODE_VALIDATION_FAILURE,
    payload: error,
  }
}
