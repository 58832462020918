import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import Cookies from 'js-cookie'

import { ObservableStates } from '../_redux/root-reducers'
import env from '../_configs/env'
import { APP_ACCESS_TOKEN_KEY_NAME } from '../_configs/token'
import { QRCODE_VALIDATION, QRCODE_VALIDATION_SUCCESS, QRCODE_VALIDATION_FAILURE } from '../states/qrcode-state'
import { qrcodeValidationSuccess, qrcodeValidationFailure } from '../actions/qrcode-action'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { QrcodeResponseInterface } from '../interfaces/qrcode-interface'
import { SessionResponseInterface } from '../interfaces/session-interface'
import { appLoaded } from '../actions/app-action'

// noinspection JSUnusedGlobalSymbols
export function qrcodeValidationService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface
) {
  return action$.pipe(
    ofType(QRCODE_VALIDATION),
    mergeMap(() =>
      dependencies
        .baseClient({
          method: 'POST',
          url: '/customers/anon/sessions',
          auth: {
            username: env.apiClientId,
            password: env.apiClientSecret,
          },
        })
        .pipe(
          mergeMap(({ data: { data } }: SessionResponseInterface) => {
            const { token, expiredAt } = data.session
            const expires = new Date(0)

            expires.setUTCSeconds(expiredAt)

            Cookies.set(APP_ACCESS_TOKEN_KEY_NAME, token, { expires })

            return dependencies
              .baseClient({
                method: 'POST',
                url: '/customers/tables/qr',
                data: {
                  code: state$.value.qrcode.code,
                },
              })
              .pipe(
                map(({ data: { data } }: QrcodeResponseInterface) => {
                  if (data.table.isReserved) {
                    window.location.href = `/invitation-code/${data.table.xid}`
                  }

                  return qrcodeValidationSuccess(data)
                }),
                catchError((error) => of(qrcodeValidationFailure(error)))
              )
          }),
          catchError((error) => of(qrcodeValidationFailure(error)))
        )
    ),
    catchError((error) => of(qrcodeValidationFailure(error)))
  )
}

// noinspection JSUnusedGlobalSymbols
export function qrcodeValidationSuccessService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(QRCODE_VALIDATION_SUCCESS),
    map(() => appLoaded())
  )
}

// noinspection JSUnusedGlobalSymbols
export function qrcodeValidationFailureService(action$: Observable<Action>) {
  return action$.pipe(
    ofType(QRCODE_VALIDATION_FAILURE),
    map(() => appLoaded())
  )
}
