import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { MenuCategoryInterface } from '../interfaces/menu-category-interface'

export const MENU_CATEGORY_FETCH_API = 'MENU_CATEGORY_FETCH_API'
export const MENU_CATEGORY_FETCH_API_SUCCESS = 'MENU_CATEGORY_FETCH_API_SUCCESS'
export const MENU_CATEGORY_FETCH_API_FAILURE = 'MENU_CATEGORY_FETCH_API_FAILURE'

export interface MenuCategoryState {
  data?: MenuCategoryInterface
  error: ErrorInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: MenuCategoryState = {
  error: {
    code: '',
    message: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [MENU_CATEGORY_FETCH_API]: (state: MenuCategoryState) => ({
    ...state,
    error: {
      code: '',
      message: '',
    },
    isLoading: true,
    isSuccess: false,
    isFailure: false,
  }),
  [MENU_CATEGORY_FETCH_API_SUCCESS]: (state: MenuCategoryState, { payload }: { payload: MenuCategoryState }) => ({
    ...state,
    data: payload.data,
    isLoading: false,
    isSuccess: true,
  }),
  [MENU_CATEGORY_FETCH_API_FAILURE]: (state: MenuCategoryState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
}

export default reducer(Object.assign(reducers), initialState)
