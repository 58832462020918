import { ErrorInterface } from '../interfaces/commons-interface'
import { OrderInterface } from '../interfaces/order-interface'
import {
  ORDER_FETCH_API,
  ORDER_FETCH_API_SUCCESS,
  ORDER_FETCH_API_FAILURE
} from '../states/order-state'

export function orderFetchApi() {
  return {
    type: ORDER_FETCH_API
  }
}

export function orderFetchApiSuccess(data: OrderInterface) {
  return {
    type: ORDER_FETCH_API_SUCCESS,
    payload: { data },
  }
}

export function orderFetchApiFailure(error: ErrorInterface) {
  return {
    type: ORDER_FETCH_API_FAILURE,
    payload: error,
  }
}
