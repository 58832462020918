import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { ReviewFormInterface } from '../interfaces/review-interface'
import { States } from '../_redux/root-reducers'

export const REVIEW_POST_API = 'REVIEW_POST_API'
export const REVIEW_POST_API_SUCCESS = 'REVIEW_POST_API_SUCCESS'
export const REVIEW_POST_API_FAILURE = 'REVIEW_POST_API_FAILURE'

export const REVIEW_POST_FORM = 'REVIEW_POST_FORM'

export interface ReviewState {
  error: ErrorInterface
  forms: ReviewFormInterface
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
}

export const initialState: ReviewState = {
  error: {
    code: '',
    message: '',
  },
  forms: {
    rating: 0,
    description: '',
  },
  isLoading: false,
  isSuccess: false,
  isFailure: false,
}

export const reducers = {
  [REVIEW_POST_API_SUCCESS]: (state: ReviewState, { payload }: { payload: { success: boolean } }) => ({
    ...state,
    forms: {
      rating: 0,
      description: '',
    },
    isLoading: false,
    isSuccess: payload.success,
  }),
  [REVIEW_POST_API_FAILURE]: (state: ReviewState, { payload }: PayloadErrorInterface) => ({
    ...state,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
    isLoading: false,
    isFailure: true,
  }),
  [REVIEW_POST_FORM]: (state: ReviewState, { payload }: { payload: ReviewFormInterface }) => ({
    ...state,
    forms: {
      ...state.forms,
      rating: payload.rating,
      description: payload.description,
    },
    isLoading: false,
    isSuccess: true,
  }),
}

export const getReviewSuccessSelector = (states: States) => states.review.isSuccess

export default reducer(Object.assign(reducers), initialState)
