const Images = {
  LogoResto: require('./logo_resto.jpeg'),
  Logo: require('./logo.svg'),
  DashLogo: require('./dash_logo.svg').default,
  Empty: require('./empty.png'),
  Food: require('./food.jpg'),
  Food2: require('./food2.jpg'),
  Error404: require('./error_404.svg').default,
  ErrorInternal: require('./error_internal.svg').default,
  ErrorLinkExpired: require('./error_link_expired.svg').default,
  ErrorMaintenance: require('./error_maintenance.svg').default,
  ErrorNetwork: require('./error_network.svg').default,
  Makanan: require('./makanan.png'),
  Bowl: require('./bowl.svg').default,
}

export default Images
