import reducer from '../_utils/reducer'
import { ErrorInterface, PayloadErrorInterface } from '../interfaces/commons-interface'
import { QrcodeInterface } from '../interfaces/qrcode-interface'
import { States } from '../_redux/root-reducers'

export const QRCODE_VALIDATION = 'QRCODE_VALIDATION'
export const QRCODE_VALIDATION_SUCCESS = 'QRCODE_VALIDATION_SUCCESS'
export const QRCODE_VALIDATION_FAILURE = 'QRCODE_VALIDATION_FAILURE'

export interface QrcodeState {
  isLoading: boolean
  isSuccess: boolean
  isFailure: boolean
  code: string
  data?: QrcodeInterface
  error: ErrorInterface
}

export const initialState: QrcodeState = {
  isLoading: true,
  isSuccess: false,
  isFailure: false,
  code: '',
  error: {
    code: '',
    message: '',
  },
}

export const reducers = {
  [QRCODE_VALIDATION]: (state: QrcodeState, { payload }: { payload: QrcodeState }) => ({
    ...state,
    isLoading: true,
    isSuccess: false,
    isFailure: false,
    error: {
      code: '',
      message: '',
    },
    code: payload.code,
  }),
  [QRCODE_VALIDATION_SUCCESS]: (state: QrcodeState, { payload }: { payload: QrcodeState }) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    data: payload.data,
  }),
  [QRCODE_VALIDATION_FAILURE]: (state: QrcodeState, { payload }: PayloadErrorInterface) => ({
    ...state,
    isLoading: false,
    isFailure: true,
    error: {
      ...state.error,
      code: payload?.code || 'INTERNAL_ERROR',
      message: payload?.message || 'Unknown Error',
    },
  }),
}

export const getQrcodeSelector = (states: States) => states.qrcode
export const getQrcodeIsLoadingSelector = (states: States) => states.qrcode.isLoading
export const getQrcodeIsSuccessSelector = (states: States) => states.qrcode.isSuccess
export const getQrcodeIsFailureSelector = (states: States) => states.qrcode.isFailure
export const getQrcodeCodeSelector = (states: States) => states.qrcode.code
export const getQrcodeErrorSelector = (states: States) => states.qrcode.error
export const getQrcodeDataSelector = (states: States) => states.qrcode.data

export default reducer(Object.assign(reducers), initialState)
