import { TOAST_SHOW, TOAST_HIDE } from '../states/toast-state'

export function toastSuccess(message: string) {
  return {
    type: TOAST_SHOW,
    payload: {
      message,
      type: 'success',
    },
  }
}

export function toastError(message: string) {
  return {
    type: TOAST_SHOW,
    payload: {
      message,
      type: 'error',
    },
  }
}

export function toastHide() {
  return {
    type: TOAST_HIDE,
  }
}
