import Icons from '../assets/icons/Icons'

export enum ReservationEnum {
  Open = 1,
  Reserved,
  ClosedByMainCustomer,
  ClosedByCaptain,
}

export const MENU_LABELS: { [index: string]: any } = {
  RECOMMENDATION: {
    bg: 'bg-blue-light',
    icon: Icons.hatChef,
    text: 'Rekomendasi',
  },
  VEGETARIAN: {
    bg: 'bg-[#41BD83]',
    icon: Icons.leaf,
    text: 'Vegetarian',
  },
  SPICY: {
    bg: 'bg-orange-light',
    icon: Icons.pepperHot,
    text: 'Pedas',
  },
  NON_HALAL: {
    bg: 'bg-purple-light',
    icon: Icons.nonHalal,
    text: 'Non-Halal',
  },
  HOT: {
    bg: 'bg-danger',
    icon: Icons.panas,
    text: 'Panas',
  },
}

export const INDONESIAN_DAYS = [
  "MINGGU",
  "SENIN",
  "SELASA",
  "RABU",
  "KAMIS",
  "JUMAT",
  "SABTU"
]

export const INDONESIAN_MONTHS = [
  "JANUARI",
  "FEBRUARI",
  "MARET",
  "APRIL",
  "MEI",
  "JUNI",
  "JULI",
  "AGUSTUS",
  "SEPTEMBER",
  "OKTOBER",
  "NOVEMBER",
  "DESEMBER"
]
