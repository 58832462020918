import { FC, useEffect } from 'react'

import IconInfo from '../../../assets/icons/info.svg'
import IconClose from '../../../assets/icons/close.svg'
import clsxm from '../../../_utils/clsxm'
import { ToastState } from '../../../states/toast-state'

interface ToastComponentProps {
  toast: ToastState
  handler: {
    hide: () => void
  }
}

const ToastComponent: FC<ToastComponentProps> = (props) => {
  const {
    toast: { show, message, type },
    handler,
  } = props

  useEffect(() => {
    if (show) {
      setTimeout(() => handler.hide(), 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return show ? (
    <div
      style={{ transform: 'translateX(-50%)' }}
      className={clsxm(
        'animate__animated animate__faster fixed top-0 z-50 flex w-full justify-between py-[21px] px-6 480:w-[480px]',
        type === 'success' && 'bg-success',
        type === 'error' && 'bg-danger',
        show ? 'animate__slideInDown' : 'animate__slideOutUp',
      )}>
      <div className='flex items-center gap-2'>
        <img
          src={IconInfo}
          alt='icon'
        />

        <div className='text-2xs leading-4 text-white'>{message}</div>
      </div>

      <button
        type='button'
        onClick={() => handler.hide()}>
        <img
          alt='close'
          src={IconClose}
        />
      </button>
    </div>
  ) : (
    <></>
  )
}

export default ToastComponent
