import { Action } from 'redux'
import { ofType } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ObservableStates } from '../_redux/root-reducers'
import { ServiceDependencyInterface } from '../interfaces/commons-interface'
import { MenuCategoryResponseInterface } from '../interfaces/menu-category-interface'
import { MENU_CATEGORY_FETCH_API } from '../states/menu-category-state'
import { menuCategoryFetchApiSuccess, menuCategoryFetchApiFailure } from '../actions/menu-category-action'

// noinspection JSUnusedGlobalSymbols
export function menuCategoryFetchApiService(
  action$: Observable<Action>,
  state$: ObservableStates,
  dependencies: ServiceDependencyInterface,
) {
  return action$.pipe(
    ofType(MENU_CATEGORY_FETCH_API),
    mergeMap(() =>
      dependencies
        .baseClient({
          method: 'GET',
          url: '/customers/menu-categories',
        })
        .pipe(
          map(({ data: { data } }: MenuCategoryResponseInterface) => menuCategoryFetchApiSuccess(data)),
          catchError((error) => of(menuCategoryFetchApiFailure(error))),
        ),
    ),
    catchError((error) => of(menuCategoryFetchApiFailure(error))),
  )
}
