import { combineEpics } from 'redux-observable'

import * as qrcodeServices from '../services/qrcode-service'
import * as invitationServices from '../services/invitation-service'
import * as registrationServices from '../services/registration-service'
import * as joinServices from '../services/join-service'
import * as helpServices from '../services/help-service'
import * as reservationServices from '../services/reservation-service'
import * as menuService from '../services/menu-service'
import * as menuCategoryService from '../services/menu-category-service'
import * as menuDetailService from '../services/menu-detail-service'
import * as cartService from '../services/cart-service'
import * as orderService from '../services/order-service'
import * as summaryService from '../services/summary-service'
import * as reviewService from '../services/review-service'
import * as invoiceService from '../services/invoice-service'
import * as paymentCashService from '../services/payment-cash-service'
import * as invitationCodeService from '../services/invitation-code-service'
import * as finsihPaymentCashService from '../services/finish-payment-cash-service'

function rootEpics(...args: any[]) {
  const allEpics: any[] = [
    ...Object.values(qrcodeServices),
    ...Object.values(invitationServices),
    ...Object.values(registrationServices),
    ...Object.values(joinServices),
    ...Object.values(helpServices),
    ...Object.values(reservationServices),
    ...Object.values(menuService),
    ...Object.values(menuCategoryService),
    ...Object.values(menuDetailService),
    ...Object.values(cartService),
    ...Object.values(orderService),
    ...Object.values(summaryService),
    ...Object.values(reviewService),
    ...Object.values(invoiceService),
    ...Object.values(paymentCashService),
    ...Object.values(invitationCodeService),
    ...Object.values(finsihPaymentCashService),
  ]

  return combineEpics(...allEpics)(...args)
}

export default rootEpics
